import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import SerranaApi from '../../clients/serrana';
import { useAddErrorMessage, useAddSuccessMessage } from '../layout/redux/addSnackMessage';
import history from '../../common/history';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(2),
  }
}));

function FreteRecusaForm({
  motivos,
  frete,
  onCancel
}) {
  const classes = useStyles();
  const { addErrorMessage } = useAddErrorMessage();
  const { addSuccessMessage } = useAddSuccessMessage();

  const onSubmit = async (values) => {
    const { motivo } = values;
    try {
      await SerranaApi.rejeitarFrete(frete.id , motivo);
      addSuccessMessage("Frete rejeitado com sucesso!");
      history.push('/frete');
    }
    catch (e) {
      addErrorMessage(e.message);
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, pristine, submitting }) => (

        <form onSubmit={handleSubmit}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Field name="motivo" type="radio">
                {({input, meta: { touched, invalid, error }}) => (
                  <FormControl error={touched && invalid} component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">Motivo</FormLabel>
                    <RadioGroup aria-label={input.name} {...input}>
                    {
                      motivos.map((motivo) => (
                        <FormControlLabel key={motivo.value} value={motivo.value} control={<Radio />} label={motivo.label} />
                      ))
                    }
                    </RadioGroup>
                    <FormHelperText>{error}</FormHelperText>
                  </FormControl>
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="secondary" className={classes.button} onClick={onCancel}>
                Cancelar
              </Button>
              <Button type="submit" variant="contained" color="primary" className={classes.button} disabled={pristine || submitting}>
                Recusar
              </Button>
            </Grid>
          </Grid>
          
        </form>
      )}
    />
  );
}

FreteRecusaForm.propTypes = {
  motivos: PropTypes.array.isRequired,
  frete: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default FreteRecusaForm;
