import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import SerranaApi from '../../../clients/serrana';

export const FRETE_GET_FRETE_MOTIVOS_BEGIN = 'FRETE_GET_FRETE_MOTIVOS_BEGIN';
export const FRETE_GET_FRETE_MOTIVOS_SUCCESS = 'FRETE_GET_FRETE_MOTIVOS_SUCCESS';
export const FRETE_GET_FRETE_MOTIVOS_FAILURE = 'FRETE_GET_FRETE_MOTIVOS_FAILURE';
export const FRETE_GET_FRETE_MOTIVOS_DISMISS_ERROR = 'FRETE_GET_FRETE_MOTIVOS_DISMISS_ERROR';


export function useGetFreteMotivos() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => {
      return getFreteMotivos(...params)(dispatch);
    },
    [dispatch]
  );
  return { getFreteMotivos: boundAction };
}

export function getFreteMotivosSelector() {
  return createStructuredSelector({
    freteMotivos: state => state.frete.freteMotivos,
    getFreteMotivosPending: state => state.frete.getFreteMotivosPending,
    getFreteMotivosError: state => state.frete.getFreteMotivosError,
  });
}

export function useGetFreteMotivosState() {
  return useSelector(getFreteMotivosSelector());
}

export function getFreteMotivos() {
  return async (dispatch) => {
    
    dispatch({
      type: FRETE_GET_FRETE_MOTIVOS_BEGIN,
    });

    try {
      const freteMotivos = await SerranaApi.getFreteMotivos();
      dispatch({
        type: FRETE_GET_FRETE_MOTIVOS_SUCCESS,
        data: {
          freteMotivos
        },
      });

      return freteMotivos;
    }
    catch (err) {
      dispatch({
        type: FRETE_GET_FRETE_MOTIVOS_FAILURE,
        data: { error: err },
      });
      
      throw err;
    }
  };
}

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export function dismissGetFreteMotivosError() {
  return {
    type: FRETE_GET_FRETE_MOTIVOS_DISMISS_ERROR,
  };
}

export function reducer(state, { type, data }) {
  switch (type) {
    case FRETE_GET_FRETE_MOTIVOS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getFreteMotivosPending: true,
        getFreteMotivosError: null,
      };

    case FRETE_GET_FRETE_MOTIVOS_SUCCESS:
      // The request is success
      const { freteMotivos } = data;

      // update state
      return {
        ...state,
        freteMotivos,
        getFreteMotivosPending: false,
        getFreteMotivosError: null,
      }

    case FRETE_GET_FRETE_MOTIVOS_FAILURE:
      // The request is failed
      return {
        ...state,
        getFreteMotivosPending: false,
        getFreteMotivosError: data.error,
      };

    case FRETE_GET_FRETE_MOTIVOS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getFreteMotivosError: null,
      };

    default:
      return state;
  }
}

export default reducer;