import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as actions from './redux/actions';
import { FORM_ERROR } from 'final-form';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Link from '../common/Link';

import { useAddErrorMessage, useAddSuccessMessage } from '../layout/redux/addSnackMessage';

import SerranaLayout from '../layout/SerranaLayout';
import FreteForm from './FreteForm';

import { useGetFreteSelecionado } from './redux/getFreteSelecionado';

const validate = (values, validateOrigens) => {
  const errors = {};

  const alwaysRequiredFields = [
    'descricaoFrete',
    'destinos[0].endereco.uf',
    'destinos[0].endereco.cidade',
    'destinos[0].numeroEntregas',
    'valorCarga',
    'peso',
    'volume',
    'produto',
    'formato',
  ];

  if (validateOrigens) {
    if (_.get(values, 'origens.length', 0) === 0) {
      _.set(errors, 'origens', 'Deve haver pelo menos uma origem para o frete.');
    }
  }

  alwaysRequiredFields.forEach(field => {
    if (_.isUndefined(values, field)) {
      _.set(errors, field, 'Campo obrigatório');
    }
  })

  const cantBeEmptyListFields = [
    'tiposVeiculos',
    'carrocerias'
  ]

  cantBeEmptyListFields.forEach(field => {
    if (_.get(values, `${field}.length`, 0) === 0) {
      _.set(errors, field, 'Campo obrigatório');
    }
  })

  // verificar demais destinos
  for (let i = 1; i < values.destinos.length; i++) {
    const destinosFields = [
      `destinos[${i}].endereco.uf`,
      `destinos[${i}].endereco.cidade`,
      `destinos[${i}].numeroEntregas`,
    ]

    destinosFields.forEach(field => {
      if (_.isUndefined(values, field)) {
        _.set(errors, field, 'Campo obrigatório');
      }
    })
  }
  

  const conditionalRequiredFields = [
    {
      field: 'usaPaleteira',
      condition: values['cargaPaletizada'],
      require: values['usaPaleteira']
    }
  ];

  conditionalRequiredFields.forEach(({ field, condition, require }) => {
    if (condition && _.isUndefined(require)) {
      errors[field] = 'Campo obrigatório'
    }
  });

  if (!_.isEmpty(errors) && !errors[FORM_ERROR]) {
    errors[FORM_ERROR] = 'Verifique os campos obrigatórios'
  }

  //console.log('errors', errors);
  return errors;
}

const realtimeValidate = (values) => {
  return validate(values, false);
}

const submitValidate = (values) => {
  return validate(values, true);
}

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const FreteNewPage = ({
  match, 
  actions, 
  produtos, 
  cargaFormatos, 
  veiculoTipos, 
  veiculoCarrocerias, 
  cidadesByUf,
  freteSelecionado, 
  getFreteSelecionadoPending
}) => {

  const { addErrorMessage } = useAddErrorMessage();
  const { addSuccessMessage } = useAddSuccessMessage();

  const { getFreteSelecionado } = useGetFreteSelecionado();

  const { freteId } = match.params;
  React.useEffect(() => {
    if (freteId) {
      getFreteSelecionado(freteId);
    }
  }, [freteId]);

  let initialValues = undefined;
  if (freteId && freteSelecionado && freteSelecionado.id) {
    console.log('freteSelecionado', freteSelecionado);
    initialValues = _.cloneDeep(freteSelecionado);
    const mapEntityToId = (entity) => (entity.id);

    if (initialValues.formato) {
      initialValues.formato = mapEntityToId(initialValues.formato);
    }
    if (initialValues.produto) {
      initialValues.produto = mapEntityToId(initialValues.produto);
    }
    initialValues.carrocerias = _.map(initialValues.carrocerias, mapEntityToId);
    initialValues.tiposVeiculos = _.map(initialValues.tiposVeiculos, mapEntityToId);
  }

  const onSubmit = async (values, form) => {
    console.log('values', values)
    // discard any non-field value
    const skipFields = ['destinos'];
    const registeredFields = _.filter(form.getRegisteredFields(), (field) => !skipFields.includes(field));
    const filteredValues = {};
    for (const field of registeredFields) {
      _.set(filteredValues, field, _.get(values, field));
    }
    values = filteredValues;

    console.log('form.getRegisteredFields()', form.getRegisteredFields());
    console.log("FILTVALUES", values);

    const validation = submitValidate(values);
    if (!_.isEmpty(validation)) {
      return validation;
    }

    values.origens = values.origens.map((origem) => ({ endereco: origem.endereco }));

    const { cadastrarFrete } = actions;
    try {
      await cadastrarFrete(values);
      addSuccessMessage('Frete cadastrado com sucesso!');
    }
    catch (e) {
      addErrorMessage(e.message);
    }
  }



  const classes = useStyles();
  
  return (
    <SerranaLayout>
      <Grid container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={3}
        style={{marginBottom: 2}}>
        <Grid item>
          <Typography component="h2" variant="overline">
            Cadastro
          </Typography>
          <Typography component="h1" variant="h3" style={{
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '28px',
              letterSpacing: '-0.06px',
            }}>
            Novo frete
          </Typography>
        </Grid>
        <Grid item>
          <Button 
            variant="contained" 
            color="inherit" 
            className={classes.button}
            component={Link} to="/frete"
            >
            Voltar
          </Button>
        </Grid>
      </Grid>
      {
        getFreteSelecionadoPending ?
          <LinearProgress />
        :
          <FreteForm 
            validate={realtimeValidate}
            onSubmit={onSubmit}
            initialValues={initialValues}
          />
      }
    </SerranaLayout>
  );
}

/* istanbul ignore next */
function mapStateToProps(state) {
  const {
    produtos, 
    cargaFormatos, 
    veiculoTipos, 
    veiculoCarrocerias, 
    cidadesByUf,
    freteSelecionado, 
    getFreteSelecionadoPending
  } = state.frete;
  return {
    produtos, 
    cargaFormatos, 
    veiculoTipos, 
    veiculoCarrocerias, 
    cidadesByUf,
    freteSelecionado, 
    getFreteSelecionadoPending
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FreteNewPage);
