import jwt from 'jsonwebtoken';
import SerranaClient from '../../../clients/serrana';
import { push } from 'connected-react-router';

export const AUTH_LOGIN_BEGIN = 'AUTH_LOGIN_BEGIN';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE';
export const AUTH_LOGIN_DISMISS_ERROR = 'AUTH_LOGIN_DISMISS_ERROR';

export function login(username, password) {
  return async (dispatch) => {
    
    dispatch({
      type: AUTH_LOGIN_BEGIN,
    });

    try {
      const accessToken = await SerranaClient.login(username, password);
      const accessTokenData = jwt.decode(accessToken);
      const { roles = [] } = accessTokenData;
      if (!roles.includes('ROLE_CLIENTE')) {
        throw new Error("Sua conta não é compatível com essa aplicação, é necessário uma conta específica.");
      }

      localStorage.setItem('accessToken', accessToken);
      dispatch({
        type: AUTH_LOGIN_SUCCESS,
        data: {
          accessToken,
          accessTokenData
        },
      });


      dispatch(push('/'));

      return accessToken;
    }
    catch (err) {
      dispatch({
        type: AUTH_LOGIN_FAILURE,
        data: { error: err },
      });
      throw err;
    }
  };
}

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export function dismissLoginError() {
  return {
    type: AUTH_LOGIN_DISMISS_ERROR,
  };
}

export function reducer(state, { type, data }) {
  switch (type) {
    case AUTH_LOGIN_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        loginPending: true,
        loginError: null,
      };

    case AUTH_LOGIN_SUCCESS:
      const { accessToken, accessTokenData } = data;

      // The request is success
      return {
        ...state,
        accessToken,
        accessTokenData,
        loginPending: false,
        loginError: null,
      };

    case AUTH_LOGIN_FAILURE:
      // The request is failed
      return {
        ...state,
        loginPending: false,
        loginError: data.error,
      };

    case AUTH_LOGIN_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        loginError: null,
      };

    default:
      return state;
  }
}