import React, { useEffect } from 'react';
import { connect } from 'react-redux'

import parseIsoDate from 'date-fns/parseISO';
import formatDate from 'date-fns/format';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DescriptionIcon from '@material-ui/icons/Description';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PrintIcon from '@material-ui/icons/Print';

import { useGetFretes } from './redux/getFretes';
import { useUpdateFretesPagination }from './redux/updateFretesPagination';
import { useAddErrorMessage } from '../layout/redux/addSnackMessage';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Link from '../common/Link';
import SerranaLayout from '../layout/SerranaLayout';
import { zeroFill, money } from '../../common/utils'

import AppDataTable from '../common/AppDataTable';
import AppButtonGroup from '../common/AppButtonGroup';
import AppStyledMenuItem from '../common/AppStyledMenuItem';
import { useHasRoles } from '../auth/auth-hooks';
import { ROLE_CADASTRO_APROVADO } from '../../common/auth';
import { FreteSituacaoChip } from '.';

const useStyles = makeStyles(theme => ({
  button: {
  },
  input: {
    display: 'none',
  },
  progressDiv: {
    flexGrow: 1,
  },
}));

const FreteAcoes = ({frete, classes}) => {
  return (
    <AppButtonGroup
      buttons={
        <Button 
          component={Link}
          to={`/frete/${frete.id}`}
          variant="outlined" 
          color="primary" 
          className={classes.button}
        >
          Visualizar
        </Button>
      }
      menuItens={(popupState) => (
          <>
            <AppStyledMenuItem 
              component={Link}
              to={`/frete/${frete.id}`}
              variant="outlined" 
              color="primary" 
              className={classes.button}
            >
              <ListItemIcon>
                <DescriptionIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Visualizar" />
            </AppStyledMenuItem>
            <AppStyledMenuItem 
              component={Link}
              to={`/frete/copy/${frete.id}`}
              variant="outlined" 
              color="primary" 
              className={classes.button}
            >
              <ListItemIcon>
                <FileCopyIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Novo frete similar..." />
            </AppStyledMenuItem>
            <AppStyledMenuItem 
              component={Link}
              to={`/frete/print/${frete.id}`}
              target='_blank'
              variant="outlined" 
              color="primary" 
              className={classes.button}
            >
              <ListItemIcon>
                <PrintIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Imprimir" />
            </AppStyledMenuItem>
          </>
        )
      }
    />
  )
}


const fetchFretes = ({currentPage, pageSize}) => {
  const { addErrorMessage } = useAddErrorMessage();
  const { getFretes } = useGetFretes();

  const doFetchFretes = async (currentPage, pageSize) => {
      try {
        await getFretes(currentPage, pageSize);
      }
      catch (e) {
        addErrorMessage(e.message);
      }
  }

  useEffect(() => {
      doFetchFretes(currentPage, pageSize);
  }, [currentPage, pageSize]);

  return {doFetchFretes};
}

const FreteListPage = ({ fretes, fretesPagination, getFretesPending }) => {
  const classes = useStyles();
  const { updateFretesPagination } = useUpdateFretesPagination();
  const { doFetchFretes } = fetchFretes(fretesPagination);
  const { hasRoles} = useHasRoles();

  const handleChangePage = (event, newPage) => {
    updateFretesPagination({
      currentPage: newPage,
    });
  };

  const handleChangeRowsPerPage = event => {
    const pageSize = parseInt(event.target.value, 10);
    updateFretesPagination({
      currentPage: 0,
      pageSize
    });
  };

  return (
    <SerranaLayout>
      <Grid container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={3}
        style={{
          marginBottom: 2,
          marginTop: 2,
        }}
      >
        <Grid item>
          <Typography component="h1" variant="h3" style={{
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '28px',
              letterSpacing: '-0.06px',
            }}>
            Fretes
          </Typography>
        </Grid>
        <Grid item>
          <Button 
            variant="contained" 
            color="primary" 
            className={classes.button}
            component={Link} to="/frete/new"
            disabled={!hasRoles(ROLE_CADASTRO_APROVADO)}
          >
            Novo frete
          </Button>
        </Grid>
      </Grid>
      <Card>
        <CardHeader 
          title={
            <Typography component="h5" style={{fontWeight: 500}}>
              Meus fretes
            </Typography>
          }
          action={
            <IconButton aria-label="refresh" title="Atualizar lista" onClick={(evt) => { doFetchFretes(); }} style={{ padding: '3px', marginRight: '5px' }}>
              <RefreshIcon />
            </IconButton>
          }
          style={{
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.05px',
          }} />
        <Divider/>
        
        <AppDataTable
          tableClassName={classes.table}
          collection={fretes}
          pagination={fretesPagination}
          loading={getFretesPending}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          tableHead={() => (
            <TableHead bgcolor="#fafafa">
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Data</TableCell>
                <TableCell>Produto</TableCell>
                <TableCell>Situação</TableCell>
                <TableCell>Valor do Orçamento</TableCell>
                <TableCell>Ação</TableCell>
              </TableRow>
            </TableHead>
          )}
          renderRow={(row) => (
            <TableRow key={row.id}>
              <TableCell>
                {zeroFill(row.numero, 4)}/{row.ano}
              </TableCell>
              <TableCell>
                {formatDate(parseIsoDate(row.dataCriacao), "dd/MM/yyyy HH:mm:ss")}
              </TableCell>
              <TableCell>
                {row.produto.nome}
              </TableCell>
              <TableCell>
                <FreteSituacaoChip situacao={row.situacao} />
              </TableCell>
              <TableCell>
                {money(row.valorOrcamento)}
              </TableCell>
              <TableCell>
                <FreteAcoes frete={row} classes={classes} />
              </TableCell>
            </TableRow>
          )}
        />
        
      </Card>
    </SerranaLayout>
  );
}

/* istanbul ignore next */
function mapStateToProps(state) {
  const { fretes, fretesPagination, getFretesPending } = state.frete;
  return {
    fretes, fretesPagination, getFretesPending
  };
}

export default connect(
  mapStateToProps
)(FreteListPage);