import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  LAYOUT_CLOSE_SNACK_MESSAGE,
} from './constants';

export function closeSnackMessage(snackId) {
  return {
    type: LAYOUT_CLOSE_SNACK_MESSAGE,
    data: {
      snackId
    }
  };
}

export function useCloseSnackMessage() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => dispatch(closeSnackMessage(...params)), [dispatch]);
  return { closeSnackMessage: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case LAYOUT_CLOSE_SNACK_MESSAGE:
      return {
        ...state,
        messages: state.messages.filter((snack) => (snack.snackId !== action.data.snackId))
      };

    default:
      return state;
  }
}
