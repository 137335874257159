import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import SerranaClient from '../../../clients/serrana';

import {
  FRETE_GET_FRETE_SELECIONADO_BEGIN,
  FRETE_GET_FRETE_SELECIONADO_SUCCESS,
  FRETE_GET_FRETE_SELECIONADO_FAILURE,
  FRETE_GET_FRETE_SELECIONADO_DISMISS_ERROR,
} from './constants';


export function useGetFreteSelecionado() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => {
      return getFreteSelecionado(...params)(dispatch);
    },
    [dispatch]
  );
  return { getFreteSelecionado: boundAction };
}

export function getFreteSelecionado(freteId) {
  return async (dispatch) => {
    
    dispatch({
      type: FRETE_GET_FRETE_SELECIONADO_BEGIN,
    });

    try {
      console.log('freteIdfreteId', freteId)
      let freteSelecionado = await SerranaClient.getFreteSelecionado(freteId);
      dispatch({
        type: FRETE_GET_FRETE_SELECIONADO_SUCCESS,
        data: {
          freteSelecionado
        },
      });

      return freteSelecionado;
    }
    catch (err) {
      dispatch({
        type: FRETE_GET_FRETE_SELECIONADO_FAILURE,
        data: { error: err },
      });
      
      throw err;
    }
  };
}

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export function dismissGetFreteSelecionadoError() {
  return {
    type: FRETE_GET_FRETE_SELECIONADO_DISMISS_ERROR,
  };
}

export function reducer(state, { type, data }) {
  switch (type) {
    case FRETE_GET_FRETE_SELECIONADO_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getFreteSelecionadoPending: true,
        getFreteSelecionadoError: null,
      };

    case FRETE_GET_FRETE_SELECIONADO_SUCCESS:
      // The request is success
      const { freteSelecionado } = data;
      return {
        ...state,
        freteSelecionado,
        getFreteSelecionadoPending: false,
        getFreteSelecionadoError: null,
      };

    case FRETE_GET_FRETE_SELECIONADO_FAILURE:
      // The request is failed
      return {
        ...state,
        getFreteSelecionadoPending: false,
        getFreteSelecionadoError: data.error,
      };

    case FRETE_GET_FRETE_SELECIONADO_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getFreteSelecionadoError: null,
      };

    default:
      return state;
  }
}