import React from 'react';
import PropTypes from 'prop-types';
import getCep from 'cep-promise';

export default function CepHelper({ cep, onCepSuccess, onCepError, onCepFetching, children }) {
  const safeOnCepFetching = (isFetching) => {
    if (onCepFetching) {
      onCepFetching(isFetching);
    }
  }

  const doFetchCep = async (cep) => {
    try {
      safeOnCepFetching(true);
      const endereco = await getCep(cep);
      onCepSuccess(endereco);
      safeOnCepFetching(false);
    }
    catch (e) {
      safeOnCepFetching(false);
      if (onCepError) {
        onCepError(e); 
      }
    }
  }

  React.useEffect(() => {
    if (cep && cep.length >= 8) {
      console.log("CEP", cep)
      doFetchCep(cep);
    }
  }, [cep]);

  return children;
}

CepHelper.propTypes = {
  onCepSuccess: PropTypes.func.isRequired,
  onCepError: PropTypes.func,
  onCepFetching: PropTypes.func,
  cep: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}