import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import SerranaClient from '../../../clients/serrana';

import {
  FRETE_GET_PRODUTOS_BEGIN,
  FRETE_GET_PRODUTOS_SUCCESS,
  FRETE_GET_PRODUTOS_FAILURE,
  FRETE_GET_PRODUTOS_DISMISS_ERROR,
} from './constants';


export function useGetProdutos() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => {
      return getProdutos(...params)(dispatch);
    },
    [dispatch]
  );
  return { getProdutos: boundAction };
}

export function getProdutos() {
  return async (dispatch) => {
    
    dispatch({
      type: FRETE_GET_PRODUTOS_BEGIN,
    });

    try {
      let produtos = await SerranaClient.getProdutos();

      dispatch({
        type: FRETE_GET_PRODUTOS_SUCCESS,
        data: produtos,
      });

      return produtos;
    }
    catch (err) {
      dispatch({
        type: FRETE_GET_PRODUTOS_FAILURE,
        data: { error: err },
      });
      
      throw err;
    }
  };
}

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export function dismissGetProdutosError() {
  return {
    type: FRETE_GET_PRODUTOS_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FRETE_GET_PRODUTOS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getProdutosPending: true,
        getProdutosError: null,
      };

    case FRETE_GET_PRODUTOS_SUCCESS:
      // The request is success
      console.log(action.data);
      return {
        ...state,
        produtos: action.data,
        getProdutosPending: false,
        getProdutosError: null,
      };

    case FRETE_GET_PRODUTOS_FAILURE:
      // The request is failed
      return {
        ...state,
        getProdutosPending: false,
        getProdutosError: action.data.error,
      };

    case FRETE_GET_PRODUTOS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getProdutosError: null,
      };

    default:
      return state;
  }
}