import React from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import SerranaLayout from '../layout/SerranaLayout';

import ChangePassForm from './ChangePassForm';

const ContaPage = () => {
  return (
    <SerranaLayout>
      <div className="conta-conta-page">
        <Grid container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={3}
          style={{
            marginBottom: 2,
            marginTop: 2,
          }}
        >
          <Grid item>
            <Typography component="h1" variant="h3" style={{
                fontSize: '24px',
                fontWeight: 500,
                lineHeight: '28px',
                letterSpacing: '-0.06px',
              }}>
              Minha conta
            </Typography>
          </Grid>
        </Grid>
        <ChangePassForm onSubmit={() => {}}/>
      </div>
    </SerranaLayout>
  );
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    conta: state.conta,
  };
}

export default connect(
  mapStateToProps
)(ContaPage);
