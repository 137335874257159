import { combineReducers } from 'redux';
// import { routerReducer } from 'react-router-redux';
import { connectRouter } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form';
import history from './history';
import homeReducer from '../features/home/redux/reducer';
import commonReducer from '../features/common/redux/reducer';
import authReducer from '../features/auth/redux/reducer';
import freteReducer from '../features/frete/redux/reducer';
import layoutReducer from '../features/layout/redux/reducer';
import contaReducer from '../features/conta/redux/reducer';
import suporteReducer from '../features/suporte/redux/reducer';
import localReducer from '../features/local/redux/reducer';

// NOTE 1: DO NOT CHANGE the 'reducerMap' name and the declaration pattern.
// This is used for Rekit cmds to register new features, remove features, etc.
// NOTE 2: always use the camel case of the feature folder name as the store branch name
// So that it's easy for others to understand it and Rekit could manage them.

const reducerMap = {
  router: connectRouter(history),
  form: formReducer,
  home: homeReducer,
  common: commonReducer,
  auth: authReducer,
  frete: freteReducer,
  layout: layoutReducer,
  conta: contaReducer,
  suporte: suporteReducer,
  local: localReducer,
};

export default combineReducers(reducerMap);
