import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { createAuthInitialState } from './initialState';

export const AUTH_LOGOUT = 'AUTH_LOGOUT';


export function logout() {
  localStorage.clear();
  return {
    type: AUTH_LOGOUT,
  };
}

export function useLogout() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => { 
      dispatch(logout(...params));
      dispatch(push('/'));
    },
    [dispatch]
  );
  return { logout: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case AUTH_LOGOUT:
      // retornar um novo estado inicial de auth
      return createAuthInitialState();

    default:
      return state;
  }
}
