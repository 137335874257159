import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import PlaceIcon from '@material-ui/icons/Place';

import NumberFormat from 'react-number-format';

import 'date-fns';
import brLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';

import { BulletList } from 'react-content-loader';

import AppSelectInput from '../common/AppSelectInput';
import SelectCidade from '../common/SelectCidade';
import SelectUnidadeFederativa from '../common/SelectUnidadeFederativa';
import AppTextField from '../common/AppTextField';
import LoadingWrapper from '../common/LoadingWrapper';
import SelectMultiple from './SelectMultiple';

import LocalMultiSelectDialog from '../local/LocalMultiSelectDialog';
import { AppAutocompleteField } from '../common';
import { useAddErrorMessage } from '../layout/redux/addSnackMessage';
import SerranaApi from '../../clients/serrana';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  fieldsDiv: {
    paddingBottom: theme.spacing(4)
  },
  progress: {
    margin: theme.spacing(2),
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  submitError: {
    color: 'red',
    fontWeight: 'bold',
  }
}));

const renderCheckbox = ({ input, label }) => (
  <div >
    <FormControlLabel style={{ width: '100%' }}
      control={
        <Checkbox
          checked={input.value ? true : false}
          onChange={input.onChange}
        />
      }
      label={label}
    />
  </div>
)

const renderOrigens = ({ fields, meta, values }) => {
  const [localMultiSelectDialogOpen, setLocalMultiSelectDialogOpen] = React.useState(false);

  const handleLocalMultiSelectDialogClose = () => {
    setLocalMultiSelectDialogOpen(false);
  }

  const classes = useStyles();
  return (
    <>
      <CardContent>
        <List dense={true}>
          {fields.map((field, index) => {
            const local = values[index];

            return (
              <ListItem key={index}>
                <ListItemAvatar>
                  <Avatar>
                    <PlaceIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={local.nome}
                  secondary={local.endereco.cidadeNome + ', ' + local.endereco.uf}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="Remover destino" onClick={() => fields.remove(index)} title="Remover destino">
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )
          }
          )}
        </List>
        {meta.submitError && !meta.dirtySinceLastSubmit && <div className={classes.submitError}>{meta.submitError}</div>}
      </CardContent>
      <Divider />
      <CardActions>
        <LocalMultiSelectDialog
          open={localMultiSelectDialogOpen}
          onClose={handleLocalMultiSelectDialogClose}
          onSelect={(local) => { fields.push(local) }}
          onDeselect={(local) => { fields.remove(_.findIndex(values, (l) => (l.id === local.id))) }}
          selected={values}
        />
        <Button variant="outlined" color="primary" onClick={() => { setLocalMultiSelectDialogOpen(true) }}>
          Selecionar origens...
        </Button>
      </CardActions>
    </>
  );
}

const renderDestinos = ({ fields, meta: { error, submitFailed }, values }) => {
  const novoDestino = {};
  if (values) {
    const lastIdx = values.length - 1;
    const lastUf = _.get(values, `[${lastIdx}]endereco.uf`);
    novoDestino.endereco = {
      uf: lastUf
    }
  }
  const handleAddDestino = () => {
    fields.push(novoDestino)
  };

  return (
    <>
      <CardContent>
        {fields.map((field, index) => {
          const uf = _.get(values, `[${index}]endereco.uf`);

          return (
            <Grid key={index} container spacing={3} alignItems="center">
              <Grid item xs={3}>
                <Field
                  name={`${field}.endereco.uf`}
                  component={SelectUnidadeFederativa}
                  label="UF"
                />
              </Grid>
              <Grid item xs={5}>
                <Field
                  name={`${field}.endereco.cidade`}
                  component={SelectCidade}
                  label="Cidade"
                  uf={uf}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name={`${field}.numeroEntregas`}
                  component={AppTextField}
                  label="Qtde. Entregas"
                  parse={(value, name) => value ? parseInt(value, 10) : null}
                />
              </Grid>
              <Grid item xs={1}>
                {
                  (fields.length > 1) ?
                    <IconButton aria-label="remove" size="small" onClick={() => fields.remove(index)} title="Remover destino">
                      <DeleteIcon />
                    </IconButton>
                    :
                    null
                }
              </Grid>
            </Grid>
          )
        }
        )}
      </CardContent>
      <Divider />
      <CardActions>
        <Button aria-label="add" size="small" color="inherit" onClick={handleAddDestino} title="Adicionar novo destino">
          <AddIcon /> Adicionar destino
        </Button>
      </CardActions>
    </>
  );
}

const DateTimeField = props => {
  const {
    meta: { submitting, error, touched },
    input: { onBlur, value, ...inputProps },
    ...others
  } = props;

  const onChange = date => {
    Date.parse(date) ? inputProps.onChange(date.toISOString()) : inputProps.onChange(null);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
      <KeyboardDateTimePicker
        {...inputProps}
        {...others}
        ampm={false}
        format="dd/MM/yyyy HH:mm:ss"
        value={value ? new Date(value) : null}
        disabled={submitting}
        onBlur={() => onBlur(value ? new Date(value).toISOString() : null)}
        error={error && touched}
        onChange={onChange}
      />
    </MuiPickersUtilsProvider>
  );
};

function DinheiroInputComponent(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => onChange({
        target: {
          value: values.floatValue,
        },
      })}
      thousandSeparator='.'
      decimalSeparator=','
      decimalScale={2}
      fixedDecimalScale={true}
      prefix="R$ "
      allowNegative={false}
    />
  );
}

function PesoInputField(props) {
  const { input, ...other } = props;
  return (
    <NumberFormat
      {...other}
      onValueChange={(values) => input.onChange(values.floatValue)}
      value={input.value}
      thousandSeparator='.'
      decimalSeparator=','
      decimalScale={2}
      fixedDecimalScale={true}
      suffix="Kg"
      allowNegative={false}
    />
  );
}

function VolumeInputField(props) {
  const { input, ...other } = props;
  return (
    <NumberFormat
      {...other}
      onValueChange={(values) => input.onChange(values.floatValue)}
      value={input.value}
      thousandSeparator='.'
      decimalSeparator=','
      decimalScale={2}
      fixedDecimalScale={true}
      suffix="m³"
      allowNegative={false}
    />
  );
}

function ProdutoGrupoSelect({
  options,
  ...field
}) {
  return (
    <AppAutocompleteField
      options={options}
      getOptionLabel={produto => produto.label}
      getOptionValue={produto => produto.value}
      groupBy={produto => produto.grupo}
      {...field}
    />
  );
}

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const useAsyncFetch = (asyncAction, defaultValue = [], errorMessage) => {
  const { addErrorMessage } = useAddErrorMessage();
  const [fetchResult, setFetchResult] = React.useState(defaultValue);
  const [fetchPending, setFetchPending] = React.useState(false);

  const doActionAsync = async () => {
    try {
      setFetchPending(true);
      const result = await asyncAction();
      console.log('ASYNC FETCH SUCCESS!!!')
      setFetchResult(result);
      setFetchPending(false);
    }
    catch (e) {
      console.log(e);
      setFetchPending(false);
      addErrorMessage(errorMessage || e.message);
    }
  }

  React.useEffect(() => {
    doActionAsync();
  }, []);

  return [fetchResult, fetchPending];
}

const FreteForm = ({
  validate,
  onSubmit,
  initialValues,
}) => {
  const classes = useStyles();

  if (!initialValues) {
    initialValues = {
      "origem": null,
      "destinos": [{}],
      "tiposVeiculos": [],
      "carrocerias": [],
      "pagaCarregar": false,
      "pagaDescarregar": false,
      "pagaPedagio": false,
      "cargaPaletizada": false,
    }
  }

  return (
    <Form
      mutators={{ ...arrayMutators }}
      validate={validate}
      initialValues={initialValues}
      onSubmit={onSubmit}
      subscription={{ pristine: true, submitting: true, submitError: true, dirtySinceLastSubmit: true }}

    >
      {({ handleSubmit, pristine, submitting, submitError, dirtySinceLastSubmit }) => {

        const mapProductToOption = (p) => ({ value: p.id, label: p.nome, grupo: p.grupo.nome });
        const mapFormatToOption = (f) => ({ value: f.id, label: f.nome });
        const mapTipoToOption = (t) => ({ value: t.id, label: t.nome });
        const mapCarroceriaToOption = (t) => ({ value: t.id, label: t.nome });

        const fetchAndMap = (fetchFunc, mapFunc) => {
          return async () => {
            const fetchResult = await fetchFunc();
            return fetchResult.map(mapFunc)
          }
        }

        const [produtos, getProdutosPending] = useAsyncFetch(fetchAndMap(SerranaApi.getProdutos, mapProductToOption));
        const [formatos, getCargaFormatosPending] = useAsyncFetch(fetchAndMap(SerranaApi.getCargaFormatos, mapFormatToOption));
        const [tiposVeiculos, getVeiculoTiposPending] = useAsyncFetch(fetchAndMap(SerranaApi.getVeiculoTipos, mapTipoToOption));
        const [tiposCarrocerias, getVeiculoCarroceriasPending] = useAsyncFetch(fetchAndMap(SerranaApi.getVeiculoCarrocerias, mapCarroceriaToOption));

        return (
          <>
            <Card className={classes.card}>
              <CardHeader
                title={
                  <Typography component="h5" style={{ fontWeight: 500 }}>
                    Carga
                  </Typography>
                }
                style={{
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '20px',
                  letterSpacing: '-0.05px',
                }} />
              <Divider />
              <CardContent>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={6} lg={3}>
                    <Field
                      name="valorCarga"
                      component={AppTextField}
                      InputProps={{
                        inputComponent: DinheiroInputComponent,
                      }}
                      label="Valor da Carga (R$)"
                    />
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <Field
                      name="peso"
                      component={PesoInputField}
                      customInput={AppTextField}
                      label="Peso (Kg)"
                    />
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <Field
                      name="volume"
                      component={VolumeInputField}
                      customInput={AppTextField}
                      label="Volume (m³)"
                    />
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <LoadingWrapper loading={getCargaFormatosPending}>
                      <Field
                        name="formato"
                        component={AppSelectInput}
                        label="Formato da Carga"
                        options={formatos}
                      />
                    </LoadingWrapper>
                  </Grid>
                </Grid>

                <LoadingWrapper loading={getProdutosPending}>
                  <Field
                    name="produto"
                    component={ProdutoGrupoSelect}
                    label="Produto"
                    options={produtos}
                  />
                </LoadingWrapper>

                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={6} lg={3}>
                    <Field
                      name="cargaRastreada"
                      component={renderCheckbox}
                      label="Carga Rastreada"
                    />
                  </Grid>

                  <Grid item xs={6} lg={3}>
                    <Field
                      name="cargaPaletizada"
                      component={renderCheckbox}
                      label="Carga Paletizada"
                    />
                  </Grid>
                  <FormSpy subscription={{ values: true }}>
                    {({ values }) => (
                      values.cargaPaletizada &&
                      <Grid item xs={6} lg={3}>
                        <Field
                          name="usaPaleteira"
                          component={AppSelectInput}
                          label="Usa Paleteira"
                          options={[
                            { label: 'Sim', value: true },
                            { label: 'Não', value: false }
                          ]}
                        />
                      </Grid>
                    )}
                  </FormSpy>
                </Grid>
              </CardContent>
            </Card>
            <Card className={classes.card}>
              <CardHeader
                title={
                  <Typography component="h5" style={{ fontWeight: 500 }}>
                    Veículo
                  </Typography>
                }
                style={{
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '20px',
                  letterSpacing: '-0.05px',
                }} />
              <Divider />
              <CardContent>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={6}>
                    <div className={classes.fieldsDiv}>
                      <h3>Tipo(s) de Veículo</h3>
                      {getVeiculoTiposPending ?
                        <BulletList />
                        :
                        <Field
                          name="tiposVeiculos"
                          component={SelectMultiple}
                          label="Tipo(s) de Veículo(s)"
                          options={tiposVeiculos}
                        />
                      }
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.fieldsDiv}>
                      <h3>Tipo(s) de Carrocerias</h3>
                      {getVeiculoCarroceriasPending ?
                        <BulletList />
                        :
                        <Field
                          name="carrocerias"
                          component={(props) => (<SelectMultiple fullWidth {...props} />)}
                          label="Tipo(s) de Carroceria(s)"
                          options={tiposCarrocerias}
                        />
                      }
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card className={classes.card}>
              <CardHeader
                title={
                  <Typography component="h5" style={{ fontWeight: 500 }}>
                    Origem
                  </Typography>
                }
                style={{
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '20px',
                  letterSpacing: '-0.05px',
                }} />
              <Divider />
              <CardContent>
                <FormSpy subscription={{ values: true }}>
                  {({ values }) => (
                    <FieldArray
                      name="origens"
                      component={renderOrigens}
                      values={values.origens}
                    />
                  )}
                </FormSpy>
              </CardContent>
            </Card>
            <Card className={classes.card}>
              <CardHeader
                title={
                  <Typography component="h5" style={{ fontWeight: 500 }}>
                    Destinos
                  </Typography>
                }
                style={{
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '20px',
                  letterSpacing: '-0.05px',
                }} />
              <Divider />
              <FormSpy subscription={{ values: true }}>
                {({ values }) => (
                  <FieldArray
                    name="destinos"
                    component={renderDestinos}
                    values={values.destinos}
                  />
                )}
              </FormSpy>
            </Card>
            <Card className={classes.card}>
              <CardHeader
                title={
                  <Typography component="h5" style={{ fontWeight: 500 }}>
                    Informação adicional
                  </Typography>
                }
                style={{
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '20px',
                  letterSpacing: '-0.05px',
                }}
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3} alignItems="flex-start">
                  <Grid item xs={6}>
                    <div className={classes.fieldsDiv}>
                      <h3>Custos extras da transportadora</h3>
                      <Grid container alignItems="center">
                        <Field
                          name="pagaCarregar"
                          component={renderCheckbox}
                          label="Custo carregar?"
                        />
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography color="inherit">Custo para carregar</Typography>
                              <Typography
                                color="inherit"
                                align="justify"
                                variant="caption"
                              >
                                Informar o valor caso exista algum custo para o transportador no momento da coleta.
                              </Typography>
                            </React.Fragment>
                          }
                        >
                          <HelpIcon />
                        </HtmlTooltip>
                      </Grid>
                      <FormSpy subscription={{ values: true }}>
                        {({ values }) => (values.pagaCarregar &&
                          <Field
                            name="valorCarregar"
                            component={AppTextField}
                            InputProps={{
                              inputComponent: DinheiroInputComponent,
                            }}
                            label="Custo p/ Carregar (R$)"
                          />
                        )}
                      </FormSpy>
                      <Grid container alignItems="center">
                        <Field
                          name="pagaDescarregar"
                          component={renderCheckbox}
                          label="Custo descarregar?"
                        />
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography color="inherit">Custo para descarregar</Typography>
                              <Typography
                                color="inherit"
                                align="justify"
                                variant="caption"
                              >
                                Informar o valor caso exista algum custo para o transportador no momento da entrega.
                              </Typography>
                            </React.Fragment>
                          }
                        >
                          <HelpIcon />
                        </HtmlTooltip>
                      </Grid>

                      <FormSpy subscription={{ values: true }}>
                        {({ values }) => (values.pagaDescarregar &&
                          <Field
                            name="valorDescarregar"
                            component={AppTextField}
                            InputProps={{
                              inputComponent: DinheiroInputComponent,
                            }}
                            label="Custo p/ Descarregar (R$)"
                          />
                        )}
                      </FormSpy>

                      <Grid container alignItems="center">
                        <Field
                          name="pagaPedagio"
                          component={renderCheckbox}
                          label="Custo pedágios?"
                        />
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography color="inherit">Custo pedágios</Typography>
                              <Typography
                                color="inherit"
                                align="justify"
                                variant="caption"
                              >
                                Informar caso os custos com pedágio(s) sejam por conta do transportador.
                              </Typography>
                            </React.Fragment>
                          }
                        >
                          <HelpIcon />
                        </HtmlTooltip>
                      </Grid>

                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div>
                      <h3>Agendamento</h3>
                      <div>
                        <Field name="dataCarregar" component={DateTimeField} label="Data Coleta" />
                      </div>
                      <div>
                        <Field name="dataDescarregar" component={DateTimeField} label="Data Entrega" />
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Field
                  name="descricaoFrete"
                  component={AppTextField}
                  label="Observações"
                  multiline={true}
                  rows={4}
                />
              </CardContent>
            </Card>
            <Button type="button" onClick={handleSubmit} variant="contained" color="primary" className={classes.button} disabled={pristine || submitting}>
              Cadastrar
            </Button>
            {submitError && !dirtySinceLastSubmit && <div className={classes.submitError}>{submitError}</div>}
          </>
        )
      }}
    </Form>
  )
}

FreteForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default FreteForm;