// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { LocalListPage } from './';
import { LocalNewPage } from './';
import { LocalEditPage } from './';

export default {
  path: 'local',
  childRoutes: [
    { path: 'new', component: LocalNewPage, roles: ['ROLE_CLIENTE'] },
    { path: 'index', component: LocalListPage, roles: ['ROLE_CLIENTE'] },
    { path: 'edit/:localId', component: LocalEditPage, roles: ['ROLE_CLIENTE'] },
  ],
};
