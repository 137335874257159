import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CheckIcon from '@material-ui/icons/Check';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ErrorIcon from '@material-ui/icons/Error';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import { getSituacaoFreteLabel } from '../../common/utils';

const useStyles = makeStyles(theme => ({
  'aguardando': {
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
  },
  'aprovado': {
    color: theme.palette.info.main,
    borderColor: theme.palette.info.main,
  },
  entregue: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    //color: '#1cc88a',
    //borderColor: '#1cc88a',
  },
  associado: {
    color: theme.palette.info.main,
    borderColor: theme.palette.info.main,
    //color: '#36b9cc',
    //borderColor: '#1cc88a',
  },
  orcado: {
    color: theme.palette.text.main,
    borderColor: theme.palette.text.main,
    //color: '#858796',
    //borderColor: '#1cc88a',
  },
  rejeitado: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    //color: '#e74a3b',
    //borderColor: '#e74a3b',
  },
  coletado: {
    color: theme.palette.info.main,
    borderColor: theme.palette.info.main,
    //color: '#36b9cc',
    //borderColor: '#36b9cc',
  },
}));

export const FRETE_SITUACAO_ICON = {
  'aguardando': HourglassEmptyIcon,
  'aprovado': CheckIcon,
  'entregue': DoneAllIcon,
  'associado': AssignmentIndIcon,
  'orcado': ReceiptIcon,
  'rejeitado': ErrorIcon,
  'coletado': LocalShippingIcon,
}

function FreteSituacaoChip({
  situacao
}) {
  const classes = useStyles();

  const situacaoId = situacao.toLowerCase();

  const situacaoClass = situacaoId;
  const SituacaoIcon = FRETE_SITUACAO_ICON[situacaoId];
  const label = getSituacaoFreteLabel(situacao);

  return (
    <Chip 
      icon={
        <If condition={SituacaoIcon}>
          <SituacaoIcon className={classes[situacaoClass]} />
        </If>
      }
      className={classes[situacaoClass]} 
      variant="outlined" 
      label={label} 
    />
  );
}

FreteSituacaoChip.propTypes = {
  situacao: PropTypes.string.isRequired,
};

export default FreteSituacaoChip;
