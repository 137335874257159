import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';

import Autocomplete from '@material-ui/lab/Autocomplete';

function AppAutocompleteField({
  label,
  placeholder,
  input,
  meta: { touched, invalid, error },
  options,
  getOptionLabel,
  getOptionValue,
  groupBy,
  inputProps,
  ...other
}) {
  return (
    <Autocomplete
      options={options.sort((a, b) => -groupBy(b).localeCompare(groupBy(a)))}
      groupBy={groupBy}
      getOptionLabel={getOptionLabel}
      onChange={(event, value) => { 
        if (value) {
          value = getOptionValue(value);
        }
        input.onChange(value); 
      }}
      renderInput={params => (
        <TextField 
          {...params} 
          label={label} 
          placeholder={placeholder} 
          fullWidth 
          error={touched && invalid}
          helperText={touched && error}
          {...input} 
          {...inputProps} 
        />
      )}
      {...other}
    />
  );
}

AppAutocompleteField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  getOptionValue: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  groupBy: PropTypes.func,
};

export default AppAutocompleteField;
