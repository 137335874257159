import React from 'react';
import PropTypes from 'prop-types';

import parseIsoDate from 'date-fns/parseISO';
import formatDate from 'date-fns/format';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

import { SnackbarMessage } from '../common';
import { useAddErrorMessage } from '../layout/redux/addSnackMessage';
import { useFetch } from '../../hooks/useFetch';
import SerranaApi from '../../clients/serrana';
import SerranaLayout from '../layout/SerranaLayout';
import { Link } from '../common';
import ChatMessagesContainer, { ChatMessage } from '../common/ChatMessagesContainer';
import ComentarioChamadoForm from './ComentarioChamadoForm';
import { useAccessTokenState } from '../auth/auth-hooks';
import { CHAMADO_SITUACAO_ABERTO } from './suporte-utils';

const useStyles = makeStyles(theme => ({
  marginVertical2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }
}));

function ChamadoViewPage({
  match
}) {
  const classes = useStyles();

  const { addErrorMessage } = useAddErrorMessage();

  const { chamadoId } = match.params;

  const fetchChamado = () => SerranaApi.getSuporteChamado(chamadoId);

  const [chamado, loading, error, refreshChamado] = useFetch(fetchChamado);

  React.useEffect(() => {
    if (!loading) {
      const interval = setInterval(refreshChamado, 5000);
      return () => clearInterval(interval);
    }
  }, [loading]);

  const avatarSerrana = (<Avatar src={require('../../images/logo-icon.png')} alt="Serrana" />);

  const avatarCliente = (<Avatar><PermIdentityIcon /></Avatar>);

  const { accessToken } = useAccessTokenState();

  const isUser = (username) => (username === accessToken.username);

  const onSubmit = async (values, form) => {
    try {
      await SerranaApi.comentarChamado({
        ...values,
        chamado: chamadoId
      });
      await refreshChamado();
      setTimeout(form.reset);
    }
    catch (e) {
      addErrorMessage(e.message);
    }
  }

  return (
    <SerranaLayout>
      <Grid container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={3}
          style={{marginBottom: 2}}>
        <Grid item>
          <Typography component="h2" variant="overline">
            Chamado
          </Typography>
          <Typography component="h1" variant="h3" style={{
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '28px',
              letterSpacing: '-0.06px',
            }}>
            {chamado ? `${formatDate(parseIsoDate(chamado.dataAbertura), "dd/MM/yyyy HH:mm:ss")} - ${chamado.titulo}` : ''}
          </Typography>
        </Grid>
        <Grid item>
          <Button 
            variant="contained" 
            color="inherit" 
            component={Link} to="/suporte"
            >
            Voltar
          </Button>
        </Grid>
      </Grid>

      <If condition={!chamado && loading}>
          <div>
            <LinearProgress />
          </div>
      </If>

      <If condition={error}>
        <SnackbarMessage 
          variant='error' 
          message={chamado ? 'Ocorreu um erro atualizando os detalhes do chamado. Verifique sua conexão.' : 'Ocorreu um erro carregando os detalhes do chamado. Verifique sua conexão.'}
          className={classes.marginVertical2}
        />
      </If>

      <If condition={chamado}>
        <ChatMessagesContainer>
          <ChatMessage avatar={avatarCliente} content={chamado.descricao} />
          <ChatMessage avatar={avatarSerrana} content="Recebemos seu chamado de suporte e em breve daremos um retorno." primary={false} />

          <For each="comentario" of={chamado.comentarios}>
            <ChatMessage key={comentario.id} avatar={isUser(comentario.autor.username) ? avatarCliente : avatarSerrana} content={comentario.descricao} primary={isUser(comentario.autor.username)} />
          </For>

          <If condition={chamado.situacao === CHAMADO_SITUACAO_ABERTO}>
            <Grid item xs={12}>
              <ComentarioChamadoForm onSubmit={onSubmit} />
            </Grid>
          </If>
        </ChatMessagesContainer>
      </If>
    </SerranaLayout>
  );
};

ChamadoViewPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  staticContext: PropTypes.object,
};

export default ChamadoViewPage;
