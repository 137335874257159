import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import byteSize from 'byte-size';

import { DropzoneArea } from 'material-ui-dropzone';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import ErrorIcon from '@material-ui/icons/Error';
import ImageIcon from '@material-ui/icons/Image';

import SerranaClient from '../../clients/serrana';


export const renderIconByFile = (type) => {
  if (!_.isUndefined(type)) {
    if (type.startsWith("image/")) {
      return (
        <ImageIcon />
      )
    }
    else if (type.startsWith("application/pdf")) {
      return (
        <DescriptionIcon />
      );
    }
    else {
      return (
        <DescriptionIcon />
      );
    }
  }

}

function ArquivoItem({
  arquivo,
  onUploadDeleted
}) {
  const fileSize = byteSize(arquivo.tamanho, { units: 'iec' });

  const handleRemoveFile = () => {
    SerranaClient.removerArquivo(arquivo.id);
    onUploadDeleted(arquivo);
  }

  return (
    <ListItem >
      <ListItemIcon>
        {renderIconByFile(arquivo.mimeType)}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography variant="body1" color="textPrimary">
            {arquivo.nomeOriginal}
          </Typography>
        }
        secondary={`${fileSize.value} ${fileSize.unit}`}
      />
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="delete" onClick={handleRemoveFile}>
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

function FilePreviewItem({
  file,
  onUploadSuccess,
  onUploadDeleted,
  onDelete,
}) {
  const [arquivo, setArquivo] = React.useState();
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [uploadError, setUploadError] = React.useState();

  const onUploadProgress = (progressEvent) => {
    const { loaded, total } = progressEvent.loaded;
    // não deixar esse evento marcar o upload como completo (100%)
    const percentCompleted = Math.min(99, Math.round((loaded * 100) / total));
    setUploadProgress(percentCompleted);
  }

  const isUploadFinished = () => (uploadProgress === 100);
  const isUploadStarting = () => (uploadProgress === 0);

  const doFileUpload = async () => {
    try {
      setUploadError(undefined);
      setUploadProgress(0);
      setArquivo(undefined);
      const arquivo = await SerranaClient.enviarArquivo(file, onUploadProgress);
      console.log('FilePreviewItem.arquivo DONE', arquivo);
      setUploadProgress(100);
      setArquivo(arquivo);
      try {
        onUploadSuccess(arquivo);
      }
      catch (err) {
        console.log('ERROR: onUploadSuccess', err);
      }
    }
    catch (e) {
      console.log('ERROR: doFileUpload', e);
      setUploadError(e.message);
    }
  };

  React.useEffect(() => {
    doFileUpload();
  }, []);

  const fileSize = byteSize(file.size, { units: 'iec' });

  const retryUpload = (event) => {
    event.preventDefault();
    doFileUpload();
  }

  const discardFailedFile = (event) => {
    event.preventDefault();
    onDelete(file);
  }

  const handleRemoveFile = () => {
    SerranaClient.removerArquivo(arquivo.id);
    onDelete(file);
    onUploadDeleted(arquivo);
  }

  return (
    <ListItem >
      <ListItemIcon>
        {renderIconByFile(file.type)}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography variant="body1" color={uploadError ? 'error' : 'textPrimary'}>
            {file.name}
          </Typography>
        }
        secondaryTypographyProps={{
          color: (uploadError ? 'error' : 'textSecondary')
        }}
        secondary={
          <>
            {
              uploadError ?
                <>
                  {fileSize.value + ' ' + fileSize.unit}
                  {uploadError && " - Falhou: "}
                  {uploadError && (
                    <>
                      <Link href="#" onClick={retryUpload}>
                        Reenviar
                    </Link>
                      {" "}
                      <Link href="#" onClick={discardFailedFile}>
                        Descartar
                    </Link>
                    </>
                  )}
                </>
                :
                `${fileSize.value} ${fileSize.unit}`
            }

          </>
        }
      />
      <Choose>
        <When condition={uploadError}>
          <ErrorIcon />
        </When>
        <Otherwise>
          <CircularProgress variant={isUploadStarting() ? 'indeterminate' : 'static'} size={24} value={uploadProgress} />
        </Otherwise>
      </Choose>
    </ListItem>
  );
}

function AppDropzone({
  arquivos,
  dropzoneText = "Clique ou arraste os arquivos aqui.",
  onChange,
  onDrop,
  onDropRejected,
  onDelete,
  onUploadSuccess,
  onUploadDeleted,
  filesLimit,
  ...other
}) {
  const dropZoneRef = React.useRef();
  const [files, setFiles] = React.useState([]);

  const removeFile = (file) => {
    const fakeEvent = {
      stopPropagation: () => { }
    }
    const { fileObjects } = dropZoneRef.current.state;
    const fileIndex = fileObjects.map(fo => fo.file).indexOf(file);
    dropZoneRef.current.handleRemove(fileIndex)(fakeEvent);
  };

  const createChangeInterceptor = (func) => {
    return (files) => {
      setFiles(files);
      console.log("SETFILES", files)
      if (_.isFunction(func)) {
        func(files);
      }
    }
  }

  return (
    <>
      <If condition={arquivos.length < filesLimit}>
        <DropzoneArea
          showPreviewsInDropzone={false}
          ref={dropZoneRef}
          dropzoneText={dropzoneText}
          onChange={createChangeInterceptor(onChange)}
          onDrop={onDrop}
          onDropRejected={onDropRejected}
          onDelete={onDelete}
          showAlerts={false}
          getFileLimitExceedMessage={filesLimit => `Número máximo de arquivos excedido. Apenas ${filesLimit} permitido(s)`}
          getFileAddedMessage={fileName => `Arquivo ${fileName} adicionado.`}
          getFileRemovedMessage={fileName => `Arquivo ${fileName} removido.`}
          getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => {
            let message = `Arquivo ${rejectedFile.name} não permitido. `;
            if (!acceptedFiles.includes(rejectedFile.type)) {
              message += 'Tipo de arquivo não suportado. '
            }
            if (rejectedFile.size > maxFileSize) {
              const fileSize = byteSize(rejectedFile.size, { units: 'iec' });
              message += `Arquivo muito grande. Tamanho máximo: ${fileSize.value} ${fileSize.unit}.`;
            }
            return message;
          }}
          filesLimit={filesLimit}
          {...other}
        />
      </If>
      <List dense={true}>
        {
          files.map((file, index) => {
            const handleUploadSuccess = (...args) => {
              // remove from upload list
              removeFile(file);
              // notify upload success
              onUploadSuccess(...args)
            }

            return (
              <FilePreviewItem
                key={`file-${index}`}
                file={file}
                onDelete={removeFile}
                onUploadSuccess={handleUploadSuccess}
                onUploadDeleted={onUploadDeleted}
              />
            )
          })
        }
        {
          arquivos.map((arquivo, index) => (
            <ArquivoItem
              key={`arquivo-${index}`}
              arquivo={arquivo}
              onUploadDeleted={onUploadDeleted}
            />
          ))
        }
      </List>
    </>
  );
}

AppDropzone.propTypes = {
  arquivos: PropTypes.array.isRequired,
  dropzoneText: PropTypes.string,
  onChange: PropTypes.func,
  onDrop: PropTypes.func,
  onDropRejected: PropTypes.func,
  onDelete: PropTypes.func,
  onUploadSuccess: PropTypes.func,
  onUploadDeleted: PropTypes.func,
};

export default AppDropzone;
