import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { Form, Field } from 'react-final-form';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import { useAddErrorMessage } from '../layout/redux/addSnackMessage';

import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import SerranaMessages from '../layout/SerranaMessages';
import { Link } from '../common';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const renderTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  type = 'text',
  ...custom
}) => {
  return <TextField
    type={type}
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
}

const LoginPage = (props) => {
  const { auth } = props;
  const classes = useStyles();

  const { addErrorMessage } = useAddErrorMessage();

  const onSubmit = async ({ username, password}) => {
    const { login } = props.actions;
    try {
      await login(username, password);
    }
    catch (e) {
      addErrorMessage(e.message);
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <div>
          <img src={require('../../images/logo.png')} alt="Serrana" />
        </div>
        <Form
          onSubmit={onSubmit}
          >
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <Field
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="E-mail"
                name="username"
                autoComplete="email"
                component={renderTextField}
                autoFocus
              />
              <Field
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                component={renderTextField}
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Manter conectado"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={submitting}
              >
                Entrar
                {auth.loginPending && <CircularProgress size={20} />}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link variant="body2">
                    Esqueceu a senha?
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/conta/precadastro" variant="body2">
                    {"Não tem conta? Cadastre-se"}
                  </Link>
                </Grid>
              </Grid>
            </form>
          )}
        </Form>
      </div>
      <SerranaMessages />
    </Container>
  );
}

LoginPage.propTypes = {
  auth: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);
