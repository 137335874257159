import React from 'react';
import PropTypes from 'prop-types';

import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import HomeIcon from '@material-ui/icons/Home';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import PlaceIcon from '@material-ui/icons/Place';
import List from '@material-ui/core/List';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import Link from '../common/Link';
import { AppListMenu, AppListMenuItem } from '../common';

export const DRAWER_WIDTH = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: DRAWER_WIDTH,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  brand: {
    maxWidth: '100%',
    maxHeight: '64px',
    paddingVertical: 5,
    marginLeft: 'auto',
  },
  toolbar: {
    ...theme.mixins.toolbar,
    textAlign: 'center',
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function ResponsiveDrawer(props) {
  const { container, mobileOpen, onCloseHandler } = props;
  const classes = useStyles();
  const theme = useTheme();

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <img src={require('../../images/logo.png')} className={classes.brand} alt="Serrana" />
      </div>
      <Divider />
      <List>
        <AppListMenuItem component={Link} to="/" icon={<HomeIcon />} text="Início" />
        <AppListMenuItem component={Link} to="/frete" icon={<LocalShippingIcon />} text="Fretes" />
        <AppListMenuItem component={Link} to="/local" icon={<PlaceIcon />} text="Locais" />
        <AppListMenu icon={<AccountCircleIcon />} text="Minha Conta">
          <AppListMenuItem component={Link} to="/conta/cadastro" icon={<PermIdentityIcon />} text="Cadastro"  />
          <AppListMenuItem component={Link} to="/conta/alterar-senha" icon={<VpnKeyIcon />} text="Alterar Senha"  />
        </AppListMenu>
        <AppListMenuItem component={Link} to="/suporte" icon={<ContactSupportIcon />} text="Suporte" />
      </List>
    </div>
  );

  return (
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={onCloseHandler}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
  );
}

ResponsiveDrawer.propTypes = {
  container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
};

export default ResponsiveDrawer;