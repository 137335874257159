import _ from 'lodash';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import SerranaClient from '../../../clients/serrana';

import {
  COMMON_GET_CIDADES_BY_UF_BEGIN,
  COMMON_GET_CIDADES_BY_UF_SUCCESS,
  COMMON_GET_CIDADES_BY_UF_FAILURE,
  COMMON_GET_CIDADES_BY_UF_DISMISS_ERROR,
} from './constants';


export function useGetCidadesByUf() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => { 
      return getCidadesByUf(...params)(dispatch);
    },
    [dispatch]
  );
  return { getCidadesByUf: boundAction };
}

export function getCidadesByUf(uf) {
  return async (dispatch) => {
    
    dispatch({
      type: COMMON_GET_CIDADES_BY_UF_BEGIN,
    });

    try {
      let cidadesByUf = await SerranaClient.getCidadesByUf(uf);


      
      dispatch({
        type: COMMON_GET_CIDADES_BY_UF_SUCCESS,
        data: _.set({}, uf, cidadesByUf)
      });

      return cidadesByUf;
    }
    catch (err) {
      dispatch({
        type: COMMON_GET_CIDADES_BY_UF_FAILURE,
        data: { error: err },
      });
      
      throw err;
    }
  };
}

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export function dismissGetCidadesByUfError() {
  return {
    type: COMMON_GET_CIDADES_BY_UF_DISMISS_ERROR,
  };
}
export function reducer(state, {type, data}) {
  switch (type) {
    case COMMON_GET_CIDADES_BY_UF_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getCidadesByUfPending: true,
        getCidadesByUfError: null,
      };

    case COMMON_GET_CIDADES_BY_UF_SUCCESS:
      // The request is success
      const newState = {
        ...state,
        cidadesByUf: {
          ...state.cidadesByUf,
          ...data
        },
        getCidadesByUfPending: false,
        getCidadesByUfError: null,
      };

      return newState;

    case COMMON_GET_CIDADES_BY_UF_FAILURE:
      // The request is failed
      return {
        ...state,
        getCidadesByUfPending: false,
        getCidadesByUfError: data.error,
      };

    case COMMON_GET_CIDADES_BY_UF_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getCidadesByUfError: null,
      };

    default:
      return state;
  }
}