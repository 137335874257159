import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export const AUTH_SAVE_POST_LOGIN_URL = 'AUTH_SAVE_POST_LOGIN_URL';

export function savePostLoginUrl(postLoginUrl) {
  return {
    type: AUTH_SAVE_POST_LOGIN_URL,
    data: {
      postLoginUrl
    }
  };
}

export function useSavePostLoginUrl() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => {
    dispatch(savePostLoginUrl(...params));
  }, [dispatch]);
  return { savePostLoginUrl: boundAction };
}

export function reducer(state, {type, data}) {
  switch (type) {
    case AUTH_SAVE_POST_LOGIN_URL:
      const { postLoginUrl } = data;
      return {
        ...state,
        postLoginUrl
      };
    default:
      return state;
  }
}
