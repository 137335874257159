import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { AppDropzone } from '.';
import { useForm, Field } from 'react-final-form';

function AppDropzoneArquivosField({
  name,
  initialArquivos,
  onDropRejected,
  ...other
}) {
  const form = useForm();
  const [arquivos, setArquivos] = React.useState(initialArquivos || []);

  React.useEffect(() => {
    form.change(name, arquivos);
    form.blur(name);
  }, [arquivos]);

  const handleUploadSuccess = (arquivo) => {
    setArquivos(arquivos => [...arquivos, arquivo]);
  }

  const handleUploadDeleted = (arquivo) => {
    setArquivos(_.filter(arquivos, (elem) => (elem.id !== arquivo.id)))
  }

  return (
    <Field name={name} {...other}>
      {fieldProps => {
        return (
          <AppDropzone
            arquivos={arquivos}
            onDropRejected={onDropRejected}
            onUploadSuccess={handleUploadSuccess}
            onUploadDeleted={handleUploadDeleted}
            dropzoneText="Clique ou arraste os arquivos de Nota Fiscal aqui."
            {...fieldProps}
          />
        );
      }}
    </Field>
  );
}

AppDropzoneArquivosField.propTypes = {
  name: PropTypes.string.isRequired,
  initialArquivos: PropTypes.array,
  onDropRejected: PropTypes.func,
};

export default AppDropzoneArquivosField;
