import React from 'react';
import PropTypes from 'prop-types';
import SerranaApi from '../../clients/serrana';
import history from '../../common/history';

import { SnackbarMessage } from '../common';
import { useAddErrorMessage, useAddSuccessMessage } from '../layout/redux/addSnackMessage';
import { useFetch } from '../../hooks/useFetch';
import { LocalForm } from '.';
import SerranaLayout from '../layout/SerranaLayout';
import Link from '../common/Link';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  button: {
  },
  input: {
    display: 'none',
  },
  progressDiv: {
    flexGrow: 1,
  },
}));

function LocalEditPage({
  match
}) {
  const classes = useStyles();

  const { addErrorMessage } = useAddErrorMessage();
  const { addSuccessMessage } = useAddSuccessMessage();

  const { localId } = match.params;

  const fetchLocal = () => SerranaApi.getLocal(localId);

  const [local, loading, error] = useFetch(fetchLocal);

  const onSubmit = async (values) => {
    try {
      await SerranaApi.alterarLocal(values);
      addSuccessMessage('Local alterado com sucesso!');
      history.push('/local');
    }
    catch (e) {
      addErrorMessage(e.message);
    }
  }

  return (
    <SerranaLayout>
      <Grid container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={3}
          style={{marginBottom: 2}}>
        <Grid item>
          <Typography component="h2" variant="overline">
            Cadastro
          </Typography>
          <Typography component="h1" variant="h3" style={{
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '28px',
              letterSpacing: '-0.06px',
            }}>
            Locais
          </Typography>
        </Grid>
        <Grid item>
          <Button 
            variant="contained" 
            color="primary" 
            className={classes.button}
            component={Link} to="/local"
            >
            Voltar
          </Button>
        </Grid>
      </Grid>
      {
        !local || loading ?
        (
          (loading) ?
          <div>
            <LinearProgress />
          </div>
          :
          <SnackbarMessage variant='error' message='Ocorreu um erro carregando os detalhes do local. Tente novamente mais tarde.' />
        )
        :
        (
          <LocalForm onSubmit={onSubmit} local={local} />
        )
      }
    </SerranaLayout>
  );
}

LocalEditPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  staticContext: PropTypes.object,
};

export default LocalEditPage;
