import React from 'react';
import { connect } from 'react-redux';



import { useRedirectToLogin } from './redux/redirectToLogin';
import { useRefreshToken } from './redux/refreshToken';
import { useRedirectToPostLoginUrl } from './redux/redirectToPostLoginUrl';

import CheckingAuth from './CheckingAuth';
import HomePage from '../home/HomePage';

const checkAuth = (auth) => {
  const { refreshToken } = useRefreshToken();
  const { redirectToLogin } = useRedirectToLogin();

  const refreshTokenAsync = async () => {
    try {
      await refreshToken();
    }
    catch (e) {
      redirectToLogin();
    }
  }

  React.useEffect(() => {
    if (!auth.accessToken) {
      if (!auth.initialAccessToken) {
        redirectToLogin();
      }
      else {
        refreshTokenAsync();
      }
    }
  }, [auth.accessToken, auth.initialAccessToken]);

}

const AutoAuthPage = ({auth}) => {
  checkAuth(auth);
  const { redirectToPostLoginUrl } = useRedirectToPostLoginUrl();

  React.useEffect(() => {
    if (auth.accessToken && auth.postLoginUrl) {
      redirectToPostLoginUrl();
    }
  }, [auth.accessToken, auth.postLoginUrl]);

  if (!auth.accessToken) {
    return <CheckingAuth />
  }
  else if (auth.postLoginUrl) {
    // redirecionando
    return <CheckingAuth />
  }
  else {
    // usuário já está logado
    return <HomePage />
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(
  mapStateToProps
)(AutoAuthPage);
