import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  FRETE_UPDATE_FRETES_PAGINATION,
} from './constants';

export function updateFretesPagination(fretesPagination) {
  return {
    type: FRETE_UPDATE_FRETES_PAGINATION,
    data: {
      fretesPagination
    }
  };
}

export function useUpdateFretesPagination() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => dispatch(updateFretesPagination(...params)), [dispatch]);
  return { updateFretesPagination: boundAction };
}

export function reducer(state, { type, data}) {
  switch (type) {
    case FRETE_UPDATE_FRETES_PAGINATION:
      const { fretesPagination } = data;
      return {
        ...state,
        fretesPagination: {
          ...state.fretesPagination,
          ...fretesPagination
        }
      };

    default:
      return state;
  }
}
