import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PlaceIcon from '@material-ui/icons/Place';
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks';

export function LocalSelectMenu({
  children,
  actions,
  local: { locais },
  label,
  onSelectLocal,
  onMenuClosed,
}) {
  React.useEffect(() => {
    actions.getLocais();
  }, []);

  const popupState = usePopupState({ variant: 'popover' })
  return (
    <div>
      <Button variant="contained" {...bindTrigger(popupState)}>
        {label}
      </Button>
      <Menu
        {...bindMenu(popupState)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {
          locais && locais.items && locais.items.map((local) => (
            <MenuItem 
              onClick={() => { 
                onSelectLocal(local);
                popupState.close();
              }}
              >
              <ListItemIcon>
                <PlaceIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={local.nome} />
            </MenuItem>
          ))
        }
        <Divider />
        { children }
      </Menu>
    </div>
  )
}

LocalSelectMenu.propTypes = {
  local: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  onSelectLocal: PropTypes.func.isRequired,
  onMenuClosed: PropTypes.func,
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    local: state.local,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocalSelectMenu);
