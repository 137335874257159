import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import Grid from '@material-ui/core/Grid';

import LoadingWrapper from '../common/LoadingWrapper';
import AppTextField from '../common/AppTextField';
import SelectUnidadeFederativa from '../common/SelectUnidadeFederativa';
import SelectCidade from '../common/SelectCidade';
import { required } from '../../common/validators';
import { ViaCepHelper } from '.';

function EnderecoFormFields({
  form,
  values,
}) {
  const [cepFetching, setCepFetching] = React.useState(false);

  const onCepSuccess = (endereco) => {
    form.change('endereco.logradouro', endereco.logradouro);
    form.change('endereco.bairro', endereco.bairro);
    form.change('endereco.uf', endereco.uf);
    form.change('endereco.cidade', parseInt(endereco.ibge, 10));
  }
  
  return (
    <ViaCepHelper 
      cep={values.endereco ? values.endereco.cep : ''} 
      onCepSuccess={onCepSuccess}
      onCepFetching={setCepFetching}
    >
      <Grid item lg={2} xs={6}>
        <Field 
          name="endereco.cep" 
          component={AppTextField} 
          label="CEP" 
          validate={required}
        />
      </Grid>
      <Grid item lg={2} xs={6}>
        <Field 
          name="endereco.numero" 
          component={AppTextField} 
          label="Número" 
          validate={required}
        />
      </Grid>
      <Grid item lg={4} xs={5}>
        <Field 
          name="endereco.complemento" 
          component={AppTextField} 
          label="Complemento"
        />
      </Grid>
      <Grid item lg={4} xs={7}>
        <Field 
          name="endereco.pontoReferencia" 
          component={AppTextField} 
          label="Ponto de referência"
        />
      </Grid>
      
      <Grid item lg={4} xs={12}>
        <LoadingWrapper loading={cepFetching}>
          <Field 
            name="endereco.logradouro" 
            component={AppTextField} 
            label="Logradouro" 
            validate={required}
          />
        </LoadingWrapper>
      </Grid>
      
      <Grid item lg={3} xs={5}>
        <LoadingWrapper loading={cepFetching}>
          <Field 
            name="endereco.bairro" 
            component={AppTextField} 
            label="Bairro" 
            validate={required}
          />
        </LoadingWrapper>
      </Grid>

      <Grid item lg={3} xs={5}>
        <LoadingWrapper loading={cepFetching}>
          <Field 
            name="endereco.cidade" 
            component={SelectCidade} 
            label="Cidade" 
            validate={required}
            uf={values.endereco ? values.endereco.uf : undefined}
            parse={(value) => (value && parseInt(value, 10))}
          />
        </LoadingWrapper>
      </Grid>
      <Grid item lg={2} xs={2}>
        <LoadingWrapper loading={cepFetching}>
          <Field 
            name="endereco.uf" 
            component={(props) => (<SelectUnidadeFederativa {...props} shortLabels/>)} 
            label="UF"
            validate={required}
          />
        </LoadingWrapper>
      </Grid>
    </ViaCepHelper>
  );
}

EnderecoFormFields.propTypes = {
  values: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

export default EnderecoFormFields;
