import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

export const AUTH_REDIRECT_TO_LOGIN = 'AUTH_REDIRECT_TO_LOGIN';

export function redirectToLogin() {
  return push('/login');
}

export function useRedirectToLogin() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => dispatch(redirectToLogin(...params)), [dispatch]);
  return { redirectToLogin: boundAction };
}

export function reducer(state, action) {
  return state;
}
