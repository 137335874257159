import _ from 'lodash';
import { useCallback } from 'react';
import { createStructuredSelector } from 'reselect';
import { useSelector } from 'react-redux';

export function accessTokenStateSelector() {
  return createStructuredSelector({
    accessToken: state => state.auth.accessTokenData,
  });
}

export function useAccessTokenState() {
  return useSelector(accessTokenStateSelector());
}

export function rolesStateSelector() {
  return createStructuredSelector({
    roles: state => state.auth.accessTokenData.roles,
  });
}

export function useRolesState() {
  return useSelector(rolesStateSelector());
}

export function useHasRoles() {
  const { roles } = useRolesState();
  const hasRoles = useCallback(
    (...testRoles) => {
      if (testRoles.length === 1 && _.isArray(testRoles[0])) {
        testRoles = testRoles[0];
      }
      if (!_.isArray(testRoles)) {
          testRoles = Array.from(testRoles);
      }
      return roles && testRoles.every(role => roles.includes(role));
    },
    [roles],
  );
  return { hasRoles };
}
