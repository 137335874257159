import React from 'react';
import { Form, Field } from 'react-final-form';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';

import AppTextField from '../common/AppTextField';
import { required } from '../../common/validators';

const useStyles = makeStyles(theme => ({
  fieldsDiv: {
    paddingBottom: theme.spacing(4)
  },
  maxWidth: {
    width: '100%',
  },
  leftAction: {
    marginLeft: 'auto',
  }
}));

const ComentarioChamadoForm = ({
  onSubmit
}) => {
  const classes = useStyles();

  return (
    <Form
      onSubmit={onSubmit}
    >
      {({handleSubmit, pristine, submitting}) => (
        <form onSubmit={handleSubmit}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <Field 
                    name="descricao" 
                    component={AppTextField}
                    multiline={true}
                    rows={8}
                    label="Resposta"
                    className={classes.maxWidth}
                    validate={required}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider/>
            <CardActions>
              <Button type="submit" variant="contained" color="primary" className={classes.leftAction} disabled={pristine || submitting}>
                Enviar
              </Button>
            </CardActions>
          </Card>
        </form>
      )}
    </Form>
  );
}

ComentarioChamadoForm.propTypes = {
};

export default ComentarioChamadoForm;
