import React from 'react';
import { connect } from 'react-redux';

import AppSnackbar from '../common/AppSnackbar';

const renderSnack = ({snackId, ...snackProps}) => {
  return <AppSnackbar key={snackProps.snackId} {...snackProps} />
}

const renderFirstSnack = (snackState) => {
  // exibir um erro por vez
  if (snackState && snackState.length > 0) {
    return renderSnack(snackState[0]);
  }
}

const SerranaMessages = (props) => {
  return (
    <>
      {renderFirstSnack(props.messages)}
    </>
  )
}

/* istanbul ignore next */
function mapStateToProps({layout: { messages }}) {
  return {
    messages
  };
}

export default connect(mapStateToProps)(SerranaMessages);
