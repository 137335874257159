import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import parseIsoDate from 'date-fns/parseISO';
import formatDate from 'date-fns/format';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import PlaceIcon from '@material-ui/icons/Place';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import { zeroFill, money, MASK_CPF, MASK_CNPJ } from '../../common/utils';
import { FreteNotaFiscalUploadForm, FreteSituacaoChip } from './';
import NumberFormat from 'react-number-format';
import FreteMap from './FreteMap';

const useStyles = makeStyles(theme => ({
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  card: {
    marginBottom: theme.spacing(4),
  },
  mapCard: {
    height: 400,
    width: '100%',
    marginBottom: theme.spacing(4),
  },
  table: {

  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  progressDiv: {
    flex: 1,
  }
}));

export function FreteDetail({
  frete,
  orcadoCardActions
}) {
  const classes = useStyles();
  console.log("FreteDetail", frete);
  
  return (
    <>
      <If condition={frete && frete.situacao === 'aprovado'}>
        <FreteNotaFiscalUploadForm frete={frete} autoSave={true} />
      </If>

      <If condition={frete && frete.viagemAtual}>
        <Card>
          <FreteMap frete={frete} />
        </Card>
      </If>

      <Card className={classes.card}>
        <CardHeader 
          title={
            <Typography component="h5" style={{fontWeight: 500}}>
              Detalhes
            </Typography>
          }
          style={{
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.05px',
          }} />
        <Divider />
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Número
              </TableCell>
              <TableCell>
                {zeroFill(frete.numero, 4)}/{frete.ano}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Situação
              </TableCell>
              <TableCell>
                <FreteSituacaoChip situacao={frete.situacao} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Data criação
              </TableCell>
              <TableCell>
                {formatDate(parseIsoDate(frete.dataCriacao), "dd/MM/yyyy HH:mm:ss")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Data última atualização
              </TableCell>
              <TableCell>
                {formatDate(parseIsoDate(frete.dataAtualizacao), "dd/MM/yyyy HH:mm:ss")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Produto
              </TableCell>
              <TableCell>
                {frete.produto.nome}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Formato
              </TableCell>
              <TableCell>
                {frete.formato.nome}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Tipo(s) de Veículo(s)
              </TableCell>
              <TableCell>
                {frete.tiposVeiculos.map((tipoVeiculo) => (
                  <Chip key={tipoVeiculo.id} label={tipoVeiculo.nome} variant="outlined" color="primary"/>
                ))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Tipo(s) de Carroceria(s)
              </TableCell>
              <TableCell>
                {frete.carrocerias.map((carroceria) => (
                  <Chip key={carroceria.id} label={carroceria.nome} variant="outlined" color="primary"/>
                ))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Origens
              </TableCell>
              <TableCell>
                <List dense={true}>
                  {_.map(frete.origens, ({endereco}, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <PlaceIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={endereco.logradouro + ', ' + endereco.numero + ' - ' + endereco.cidadeNome  + ', ' + endereco.uf}
                        secondary={endereco.complemento + (endereco.pontoReferencia ? (' - ' + endereco.pontoReferencia) : '')}
                      />
                    </ListItem>
                    ))}
                </List>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Destinos
              </TableCell>
              <TableCell>
                <List dense={true}>
                  {_.map(frete.destinos, ({endereco, numeroEntregas}, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <PlaceIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={endereco.cidadeNome+" - "+endereco.uf}
                        secondary={'Quantidade de entregas: '+numeroEntregas}
                      />
                    </ListItem>
                  ))}
                </List>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Informação adicional
              </TableCell>
              <TableCell>
                {frete.descricaoFrete}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
      <If condition={frete && frete.situacao !== 'aguardando'}>
        <>
          <Card className={classes.card}>
            <CardHeader 
              title={
                <Typography component="h5" style={{fontWeight: 500}}>
                  Orçamento
                </Typography>
              }
              style={{
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '20px',
                letterSpacing: '-0.05px',
              }} />
            <Divider />
            <Table className={classes.table}>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Valor
                  </TableCell>
                  <TableCell>
                    {money(frete.valorOrcamento)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Orçado em
                  </TableCell>
                  <TableCell>
                    {frete.dataOrcamento ? formatDate(parseIsoDate(frete.dataOrcamento), "dd/MM/yyyy HH:mm:ss") : "null"}
                  </TableCell>
                </TableRow>
                <If condition={frete.dataAprovacao}>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Aprovado em
                    </TableCell>
                    <TableCell>
                      {formatDate(parseIsoDate(frete.dataAprovacao), "dd/MM/yyyy HH:mm:ss")}
                    </TableCell>
                  </TableRow>
                </If>
                <If condition={frete.dataRejeicao}>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Rejeitado em
                    </TableCell>
                    <TableCell>
                      {formatDate(parseIsoDate(frete.dataRejeicao), "dd/MM/yyyy HH:mm:ss")}
                    </TableCell>
                  </TableRow>
                </If>
              </TableBody>
            </Table>
            <Divider />
            {
              <If condition={frete && frete.situacao === 'orcado'}>
                <CardActions>
                  { orcadoCardActions }
                </CardActions>
              </If>
            }
          </Card>
        </>
      </If>

      <If condition={frete && !_.isEmpty(frete.viagens)}>
        <For each="viagem" index="idx" of={frete.viagens}>
          <Card key={viagem.id} className={classes.card}>
            <CardHeader 
              title={
                <Typography component="h5" style={{fontWeight: 500}}>
                  Transportador {frete.viagens.length > 1 ? `#${(idx+1)}` : ''}
                </Typography>
              }
              style={{
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '20px',
                letterSpacing: '-0.05px',
              }} />
            <Divider />
            <Table className={classes.table}>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Nome
                  </TableCell>
                  <TableCell>
                    {viagem.fornecedor.nome}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CPF/CNPJ
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      allowLeadingZeros={true}
                      displayType="text"
                      value={viagem.fornecedor.documento}
                      format={viagem.fornecedor.documento.toString().length <= 11 ? MASK_CPF : MASK_CNPJ}
                      isNumericString
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Veículo
                  </TableCell>
                  <TableCell>
                    {viagem.veiculo.marca.nome} {viagem.veiculo.modelo} - {viagem.veiculo.ano} - <Chip label={viagem.veiculo.placa} variant="outlined" size="small" color="primary"/>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
        </For>
      </If>
    </>
  );
}

FreteDetail.propTypes = {
  frete: PropTypes.object.isRequired,
  orcadoCardActions: PropTypes.element,
};

export default FreteDetail;
