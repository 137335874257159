import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { LocalForm } from '.';
import { useAddErrorMessage, useAddSuccessMessage } from '../layout/redux/addSnackMessage';

export function LocalNewDialog({
  local,
  actions,
  open,
  onClose,
}) {
  const { addErrorMessage } = useAddErrorMessage();
  const { addSuccessMessage } = useAddSuccessMessage();

  const onSubmit = async (values) => {
    try {
      await actions.cadastrarLocal(values);
      addSuccessMessage('Local cadastrado com sucesso!');
      onClose();
    }
    catch (e) {
      addErrorMessage(e.message);
    }
  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Novo Local</DialogTitle>
      <LocalForm onSubmit={onSubmit} />
    </Dialog>
  );
}

LocalNewDialog.propTypes = {
  local: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    local: state.local,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocalNewDialog);
