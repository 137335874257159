import Axios from "axios";

const api = Axios.create({
    baseURL: 'https://viacep.com.br/ws'
});

const getCep = async (cep) => {
  const res = await api.get(`/${cep}/json`);
  return res.data;
}

export default {
  api,
  getCep
};