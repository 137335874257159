
export const MASK_CNPJ = "##.###.###/####-##";

export const MASK_CPF = "###.###.###-##";

export const zeroFill = (number, length) => {
    return new Array(length - number.toString().length + 1).join('0') + number
}

export const money = (number, currency) => {
    currency = currency || 'R$'
    if (number) {
        return 'R$ ' + number.toFixed(2).replace(".",",")
    }
    return number
}

export const getSituacaoFreteLabel = (situacao) => {
    switch (situacao) {
        case 'aguardando':
            return 'Aguardando'
        case 'orcado':
            return 'Orçado'
        case 'aprovado':
            return 'Aprovado'
        case 'rejeitado':
            return 'Rejeitado'
        case 'associado':
            return 'Associado'
        case 'coletado':
            return 'Coletado'
        case 'entregue':
            return 'Entregue'
        default:
            return situacao
    }
}