import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function AppListMenu({
  icon,
  text,
  initialOpen = false,
  children,
}) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(initialOpen);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
    <ListItem button onClick={handleClick}>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
      <ListItemText primary={text} />
      {open ? <ExpandLess /> : <ExpandMore />}
    </ListItem>
    {
      children &&
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className={classes.nested}>
          {children}
        </List>
      </Collapse>
    }
    </>
  );
}

AppListMenu.propTypes = {
  icon: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
  initialOpen: PropTypes.bool,
};

export default AppListMenu;
