import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import AppSecretField from '../common/AppSecretField';

const useStyles = makeStyles(theme => ({
  fieldsDiv: {
    paddingBottom: theme.spacing(4)
  }
}));

const ChangePassForm = ({handleSubmit, pristine, submitting, onSubmit}) => {
  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card className={classes.card}>
        <CardHeader 
          title={
            <Typography component="h5" style={{fontWeight: 500}}>
              Alterar senha
            </Typography>
          }
          style={{
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.05px',
          }} />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <Field 
                name="senhaAtual" 
                component={AppSecretField}
                label="Senha Atual"
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <Field 
                name="novaSenha" 
                component={AppSecretField}
                label="Nova Senha"
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <Field 
                name="confirmaSenha" 
                component={AppSecretField}
                label="Confirmar Nova Senha"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button type="submit" variant="contained" color="primary" className={classes.button} disabled={pristine || submitting}>
            Alterar Senha
          </Button>
        </CardActions>
      </Card>
    </form>
  );
}

const FORM_NAME = 'ChangePassForm';

ChangePassForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

let ReduxForm = reduxForm({
  form: FORM_NAME,
  initialValues: {},
})(ChangePassForm);

export default ReduxForm;