import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { AppNumberInput } from '.';

function AppTelefoneInput(props) {
  const { value } = props;
  return (
    <AppNumberInput 
      format={(_.isEmpty(value) || value.length <= 10) ? "(##) ####-#####" : "(##) #####-####"}
      {...props} 
    />
  );
}

AppTelefoneInput.propTypes = {
  value: PropTypes.string
};

export default AppTelefoneInput;