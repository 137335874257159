import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    display: 'flex',
    flex: 1,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function SelectMultiple({
  input,
  label,
  meta: { touched, invalid, error },
  value,
  onChange,
  options,
  ...other
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [values, setValues] = React.useState([]);

  const onChangeHandler = event => {
    setValues(event.target.value);
  };

  const labelsByValue = new Map(options.map(opt => [opt.value, opt.label]));

  return (
    <FormControl className={classes.formControl} error={touched && invalid}>
      <InputLabel htmlFor={input.name}>{label}</InputLabel>
      <Select
        multiple
        value={value}
        {...input}
        {...other}
        onChange={(event) => {
          onChangeHandler(event);
          if (input.onChange) {
            input.onChange(event);
          }
        }}
        input={<Input id={input.name} />}
        renderValue={selected => (
          <div className={classes.chips}>
            {selected.map(value => (
              <Chip key={value} label={labelsByValue.get(value)} className={classes.chip} />
            ))}
          </div>
        )}
        MenuProps={MenuProps}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value} style={getStyles(option.value, values, theme)}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  )
}

SelectMultiple.propTypes = {
  input: PropTypes.element.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.array,
  options: PropTypes.array.isRequired
}