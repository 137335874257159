import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export const LOCAL_UPDATE_LOCAIS_PAGINATION = 'LOCAL_UPDATE_LOCAIS_PAGINATION';

export function updateLocaisPagination(args) {
  return {
    type: LOCAL_UPDATE_LOCAIS_PAGINATION,
    data: {
      args
    }
  };
}

export function useUpdateLocaisPagination() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => dispatch(updateLocaisPagination(...params)), [dispatch]);
  return { updateLocaisPagination: boundAction };
}

export default function reducer(state, { type, data}) {
  switch (type) {
    case LOCAL_UPDATE_LOCAIS_PAGINATION:
      return {
        ...state
      };

    default:
      return state;
  }
}