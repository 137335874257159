import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '../common/Link';

import SerranaLayout from '../layout/SerranaLayout';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
}));

const HomePage = () => {
  const classes = useStyles();

  return (
    <SerranaLayout>
      <div>
        <Paper className={classes.root}>
          <Typography variant="h5" component="h3">
            Serrana Frete Fácil
          </Typography>
          <Typography component="p">
            Bem-vindo ao Frete Fácil, para solicitar um novo frete ou acompanhar os fretes já aprovados, <Link to="/frete">clique aqui</Link>.
          </Typography>
        </Paper>
      </div>
    </SerranaLayout>
  );
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

export default connect(
  mapStateToProps
)(HomePage);
