import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import SerranaClient from '../../../clients/serrana';

import {
  FRETE_GET_CARGA_FORMATOS_BEGIN,
  FRETE_GET_CARGA_FORMATOS_SUCCESS,
  FRETE_GET_CARGA_FORMATOS_FAILURE,
  FRETE_GET_CARGA_FORMATOS_DISMISS_ERROR,
} from './constants';


export function useGetCargaFormatos() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => { 
      return getCargaFormatos(...params)(dispatch);
    },
    [dispatch]
  );
  return { getCargaFormatos: boundAction };
}

export function getCargaFormatos() {
  return async (dispatch) => {
    
    dispatch({
      type: FRETE_GET_CARGA_FORMATOS_BEGIN,
    });

    try {
      let cargaFormatos = await SerranaClient.getCargaFormatos();
      
      dispatch({
        type: FRETE_GET_CARGA_FORMATOS_SUCCESS,
        data: cargaFormatos,
      });

      return cargaFormatos;
    }
    catch (err) {
      dispatch({
        type: FRETE_GET_CARGA_FORMATOS_FAILURE,
        data: { error: err },
      });
      
      throw err;
    }
  };
}

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export function dismissGetCargaFormatosError() {
  return {
    type: FRETE_GET_CARGA_FORMATOS_DISMISS_ERROR,
  };
}

const initialState = {
  fretes: []
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case FRETE_GET_CARGA_FORMATOS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getCargaFormatosPending: true,
        getCargaFormatosError: null,
      };

    case FRETE_GET_CARGA_FORMATOS_SUCCESS:
      // The request is success
      console.log(action.data);
      return {
        ...state,
        cargaFormatos: action.data,
        getCargaFormatosPending: false,
        getCargaFormatosError: null,
      };

    case FRETE_GET_CARGA_FORMATOS_FAILURE:
      // The request is failed
      return {
        ...state,
        getCargaFormatosPending: false,
        getCargaFormatosError: action.data.error,
      };

    case FRETE_GET_CARGA_FORMATOS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getCargaFormatosError: null,
      };

    default:
      return state;
  }
}