import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
  },
  progress: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  media: {
    height: 76,
  },
}));

export default function CheckingAuth() {
  const classes = useStyles();
  
  return (
    <Container component="main" className={classes.paper} maxWidth="xs">
      <CssBaseline/>
        <div>
          <img src={require('../../images/logo.png')} alt="Serrana" />
        </div>
        <Typography gutterBottom variant="h5" component="h2">
          Verificando sessão...
        </Typography>
        
        <Typography variant="body2" color="textSecondary" component="p">
          Se sua última sessão ainda for válida você será logado automáticamente, 
          caso contrário será redirecionado para a tela de login.
        </Typography>

        <LinearProgress className={classes.progress} />
    </Container>
  );
}
