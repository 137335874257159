import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

export const AUTH_REDIRECT_TO_POST_LOGIN_URL = 'AUTH_REDIRECT_TO_POST_LOGIN_URL';

export function redirectToPostLoginUrl() {
  return {
    type: AUTH_REDIRECT_TO_POST_LOGIN_URL,
  };
}

export function useRedirectToPostLoginUrl() {
  const dispatch = useDispatch();
  const postLoginUrl = useSelector(state => state.auth.postLoginUrl);
  const boundAction = useCallback((...params) => {
    dispatch(redirectToPostLoginUrl(...params));
    dispatch(push(postLoginUrl));
  }, [dispatch]);
  return { redirectToPostLoginUrl: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case AUTH_REDIRECT_TO_POST_LOGIN_URL:
      return {
        ...state,
        postLoginUrl: undefined
      };

    default:
      return state;
  }
}
