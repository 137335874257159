import React from 'react';
import PropTypes from 'prop-types';
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2)
  }
}));

export function ChatMessage({ 
  content, 
  avatar,
  primary = true 
}) {
  const classes = useStyles();

  return (
    <Grid container item direction={primary ? "row" : "row-reverse"}>
      <Grid item xs={11}>
        <Paper className={classes.paper}>
          <Grid
            container
            wrap="nowrap"
            spacing={2}
            alignItems="center"
            justify="center"
            direction={primary ? "row" : "row-reverse"}
          >
            <Grid item>
              {avatar}
            </Grid>
            <Grid item xs>
              <Typography color={primary ? "textPrimary" : "primary"}>
                {content}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

ChatMessage.propTypes = {
  content: PropTypes.string.isRequired,
  avatar: PropTypes.element.isRequired,
  primary: PropTypes.bool,
};

function ChatMessagesContainer({
  spacing = 4,
  children
}) {
  return (
    <Grid container spacing={spacing}>
      {children}
    </Grid>
  );
}

ChatMessagesContainer.propTypes = {
};

export default ChatMessagesContainer;
