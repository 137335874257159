import _ from 'lodash';
import Axios from "axios";
import store from '../common/store';

export const SERRANA_GOOGLE_MAPS_KEY = "AIzaSyD0Jth5UvdD3IfSACL21WF44YGjXjqmvZk";

const errorDecorator = (err) => {
  let errorMessage = err.message;
  console.log("errorDecorator", err.response)
  if (err.response && err.response.data) {
    if (err.response.data.message) {
      errorMessage = err.response.data.message
    }
    else if (err.response.data.detail) {
      errorMessage = err.response.data.detail
    }
    else if (err.response.data['hydra:description']) {
      errorMessage = err.response.data['hydra:description']
    }


    if ("Expired JWT Token" === errorMessage) {
      err.tokenExpired = true;
      localStorage.removeItem('accessToken');
    }
  }
  err.originalMessage = err.message;
  err.message = errorMessage;
  return Promise.reject(err);
}

class HydraCollection {
  constructor(hydraData) {
    this._totalItems = hydraData['hydra:totalItems'];
    this._itemsById = _.keyBy(hydraData['hydra:member'], (item) => (item.id));
  }

  get totalItems() {
    return this._totalItems;
  }

  get items() {
    return _.values(this.itemsById);
  }

  get itemsById() {
    return this._itemsById;
  }
}

class SerranaClient {
  constructor(baseURL) {
    this.api = Axios.create({
        baseURL,
        headers: {
            'Accept-Language': 'pt-BR, pt;q=0.9, en;q=0.8'
        }
    });
    this.api.interceptors.response.use((response) => (response), errorDecorator);
    this.api.interceptors.request.use(
      (config) => {
        const state = store.getState();
        let token = undefined;

        if (state.auth.accessToken) {
          token = state.auth.accessToken;
        }
        else if (state.auth.initialAccessToken) {
          token = state.auth.initialAccessToken;
        }

        if (config.ignoreAuthToken === true) {
          delete config.headers.authorization;
        }
        else if (token) {
          config.headers.authorization = `Bearer ${token}`;
        }
        return config;
      },
      error => Promise.reject(error)
    );
  }

  preCadastrar = async (nome, email, senha, confirmaSenha) => {
    const res = await this.api.post('/precadastro/cliente', {
      nome, 
      email, 
      senha, 
      confirmaSenha
    }, {
      ignoreAuthToken: true
    });

    return res.data;
  }

  cadastrarCliente = async (cliente) => {
    const res = await this.api.post('/cadastro/cliente', cliente);
    return res.data;
  }

  login = async (username, password) => {
    const res = await this.api.post('/token', {
        username,
        password
    }, {
      ignoreAuthToken: true
    });

    return res.data.token;
  }

  getMe = async () => {
    const res = await this.api.get('/me');
    return res.data;
  }

  refreshToken = async () => {
    const res = await this.api.post('/refresh');
    return res.data.token;
  }

  cadastrarFrete = async (frete) => {
    return this.api.post('/fretes', frete);
  }

  aprovarFrete = async (freteId) => {
    const res = await this.api.post(`/fretes/${freteId}/aprovar`);
    return res.data;
  }

  rejeitarFrete = async (freteId, motivoId) => {
    const res = await this.api.post(`/fretes/${freteId}/rejeitar`,{
      motivo: motivoId
    });
    return res.data;
  }

  getFretes = async (currentPage, pageSize) => {
    if (!isNaN(currentPage)) {
      // converter de 0-based pra 1-based
      currentPage++;
    }

    const params = {
      'order[dataAtualizacao]': 'desc',
      page: currentPage,
      itemsPerPage: pageSize
    };
    
    const res = await this.api.get('/fretes', {
      params
    });

    return new HydraCollection(res.data);
  }

  getLocal = async (localId) => {
    const res = await this.api.get(`/locais/${localId}`);
    return res.data
  }

  getLocais = async (currentPage, pageSize) => {
    if (!isNaN(currentPage)) {
      // converter de 0-based pra 1-based
      currentPage++;
    }

    const params = {
      page: currentPage,
      itemsPerPage: pageSize
    };
    
    const res = await this.api.get('/locais', {
      params
    });

    return new HydraCollection(res.data);
  }

  cadastrarLocal = async (local) => {
    return this.api.post('/locais', local);
  }

  alterarLocal = async (local) => {
    return this.api.put(`/locais/${local.id}`, local);
  }

  removerLocal = async (localId) => {
    const res = await this.api.delete(`/locais/${localId}`);
    return res.data;
  }

  getFreteSelecionado = async (freteId) => {
    const res = await this.api.get(`/fretes/${freteId}`);
    return res.data;
  }

  getCargaGrupos = async () => {
    const res = await this.api.get(`/carga/grupos`);
    return res.data;
  }

  getProdutos = async () => {
    const res = await this.api.get('/carga/produtos');
    return res.data['hydra:member'];
  }

  getCargaFormatos = async () => {
    const res = await this.api.get('/carga/formatos');
    return res.data['hydra:member'];
  }

  getVeiculoTipos = async () => {
    const res = await this.api.get('/veiculo/tipos');
    return res.data['hydra:member'];
  }

  getVeiculoCarrocerias = async () => {
    const res = await this.api.get('/veiculo/carrocerias');
    return res.data['hydra:member'];
  }

  getCidadesByUf = async (uf) => {
    const res = await this.api.get(`/endereco/ufs/${uf}/cidades?pagination=false`);
    return res.data['hydra:member'];
  }

  cadastrarSuporteChamado = async (chamado) => {
    const res = await this.api.post('/suporte/chamados', chamado);
    return res.data;
  }

  getSuporteChamados = async () => {
    const res = await this.api.get('/suporte/chamados');
    return res.data['hydra:member'];
  }

  getSuporteChamado = async (chamadoId) => {
    const res = await this.api.get(`/suporte/chamados/${chamadoId}`);
    return res.data;
  }

  comentarChamado = async (values) => {
    const res = await this.api.post('/suporte/comentarios', values);
    return res.data;
  }

  enviarArquivo = async (file, onUploadProgress) => {
    const config = {
      onUploadProgress
    }

    const formData = new FormData();
    formData.append('file', file);
    const res =  await this.api.post('/arquivos', formData, config);
    return res.data;
  }

  removerArquivo = async (arquivoId) => {
    // TODO API PENDING
    console.log("SerranaClient.removerArquivo", arquivoId);
  }

  freteAssociarArquivos = async (freteId, arquivoIds) => {
    console.log('freteAssociarArquivos called', freteId, arquivoIds);
    const res =  await this.api.post(`/fretes/${freteId}/arquivos`, arquivoIds);
    console.log('freteAssociarArquivos', res);
    return res.data;
  }

  getFreteMotivos = async () => {
    const res = await this.api.get(`/frete/motivos?pagination=false`);
    return res.data['hydra:member'];
  }
  
}

const baseURL = process.env.REACT_APP_SERRANA_API_URL;
if (!baseURL) {
  throw new Error("Missing REACT_APP_SERRANA_API_URL env var.")
}
console.log("baseURL", baseURL);
const SerranaApi = new SerranaClient(baseURL);

export default SerranaApi;
