import React from 'react';
import { useSelector } from 'react-redux'
import { useLogout } from '../auth/redux/logout';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';

import { DRAWER_WIDTH } from './SerranaDrawer';

const useStyles = makeStyles(theme => ({
  appBar: {
    marginLeft: DRAWER_WIDTH,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
}));

const SerranaAppBar = ({onClick}) => {
  const classes = useStyles();
  const auth = useSelector(state => state.auth);
  const { logout } = useLogout();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="open drawer"
          onClick={onClick}
        >
          <MenuIcon />
        </IconButton>
        <Typography className={classes.title} variant="h6" noWrap>
          Serrana Frete Fácil
        </Typography>
        { auth.accessToken && 
          <IconButton
            variant="contained" 
            className={classes.button}
            title="Sair"
            color="inherit"
            onClick={() => logout()}>
            <ExitToApp />
          </IconButton>
        }
      </Toolbar>
    </AppBar>
  );
}

export default SerranaAppBar;