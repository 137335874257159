import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { useAddErrorMessage, useAddSuccessMessage } from '../layout/redux/addSnackMessage';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Link from '../common/Link';
import SerranaLayout from '../layout/SerranaLayout';

import AppDataTable from '../common/AppDataTable';
import AppButtonGroup from '../common/AppButtonGroup';
import AppStyledMenuItem from '../common/AppStyledMenuItem';
import AppConfirmDialog from '../common/AppConfirmDialog';
import { useRemoverLocal, useRemoverLocalState } from './redux/removerLocal';

const useStyles = makeStyles(theme => ({
  button: {
  },
  input: {
    display: 'none',
  },
  progressDiv: {
    flexGrow: 1,
  },
}));

const LocalAcoes = ({local, classes, doFetchLocais}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const { removerLocal } = useRemoverLocal();
  const { addErrorMessage } = useAddErrorMessage();
  const { addSuccessMessage } = useAddSuccessMessage();
  const { removerLocalPending } = useRemoverLocalState();

  const handleRemoveLocal = async (localId) => {
    try {
      await removerLocal(localId);
      addSuccessMessage('Local removido com sucesso!');
      doFetchLocais();
      setDeleteDialogOpen(false);
    }
    catch (e) {
      addErrorMessage(e.message);
    }
  }

  return (
    <>
      <AppButtonGroup
        buttons={
          <Button 
            component={Link}
            to={`/local/edit/${local.id}`}
            variant="outlined" 
            color="primary" 
            className={classes.button}
          >
            Editar
          </Button>
        }
        menuItens={(popupState) =>
          (
            <>
              <AppStyledMenuItem 
                component={Link}
                to={`/local/edit/${local.id}`}
                variant="outlined" 
                color="primary" 
                className={classes.button}
              >
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Editar" />
              </AppStyledMenuItem>
              <AppStyledMenuItem
                onClick={event => { popupState.close(); setDeleteDialogOpen(true) }}
              >
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Remover" />
              </AppStyledMenuItem>
            </>
          )
        }
      />
      <AppConfirmDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={() => handleRemoveLocal(local.id)}
        title={`Remover "${local.nome}"?`}
        message={`Tem certeza que deseja remover o local?`}
        actionInProgress={removerLocalPending}
      />
    </>
  );
}


const fetchLocais = (getLocais, {currentPage, pageSize}) => {
  const { addErrorMessage } = useAddErrorMessage();

  const doFetchLocais = async (currentPage, pageSize) => {
      try {
        await getLocais(currentPage, pageSize);
      }
      catch (e) {
        addErrorMessage(e.message);
      }
  }

  React.useEffect(() => {
      doFetchLocais(currentPage, pageSize);
  }, [currentPage, pageSize]);

  return {doFetchLocais};
}

const LocalListPage = ({ actions, local: {locais, locaisPagination, getLocaisPending} }) => {
  const classes = useStyles();
  const { doFetchLocais } = fetchLocais(actions.getLocais, locaisPagination);

  const handleChangePage = (event, newPage) => {
    actions.updateLocaisPagination({
      currentPage: newPage,
    });
  };

  const handleChangeRowsPerPage = event => {
    const pageSize = parseInt(event.target.value, 10);
    actions.updateLocaisPagination({
      currentPage: 0,
      pageSize
    });
  };

  return (
    <SerranaLayout>
      <Grid container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={3}
        style={{
          marginBottom: 2,
          marginTop: 2,
        }}
      >
        <Grid item>
          <Typography component="h1" variant="h3" style={{
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '28px',
              letterSpacing: '-0.06px',
            }}>
            Locais
          </Typography>
        </Grid>
        <Grid item>
          <Button 
            variant="contained" 
            color="primary" 
            className={classes.button}
            component={Link} to="/local/new"
            >
            Novo local
          </Button>
        </Grid>
      </Grid>
      <Card>
        <CardHeader 
          title={
            <Typography component="h5" style={{fontWeight: 500}}>
              Meus locais
            </Typography>
          }
          action={
            <IconButton aria-label="refresh" title="Atualizar lista" onClick={(evt) => { doFetchLocais(); }} style={{ padding: '3px', marginRight: '5px' }}>
              <RefreshIcon />
            </IconButton>
          }
          style={{
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.05px',
          }} />
        <Divider/>
        
        <AppDataTable
          tableClassName={classes.table}
          collection={locais}
          pagination={locaisPagination}
          loading={getLocaisPending}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          tableHead={() => (
            <TableHead bgcolor="#fafafa">
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Cep</TableCell>
                <TableCell>Cidade</TableCell>
                <TableCell>UF</TableCell>
                <TableCell>Ação</TableCell>
              </TableRow>
            </TableHead>
          )}
          renderRow={(row) => (
            <TableRow key={row.id}>
              <TableCell>
                {row.nome}
              </TableCell>
              <TableCell>
                {row.endereco.cep}
              </TableCell>
              <TableCell>
                {row.endereco.cidadeNome}
              </TableCell>
              <TableCell>
                {row.endereco.uf}
              </TableCell>
              <TableCell>
                <LocalAcoes local={row} classes={classes} doFetchLocais={doFetchLocais} />
              </TableCell>
            </TableRow>
          )}
        />
        
      </Card>
    </SerranaLayout>
  );
}

LocalListPage.propTypes = {
  local: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    local: state.local,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocalListPage);
