import jwt from 'jsonwebtoken';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import SerranaClient from '../../../clients/serrana';

export const AUTH_REFRESH_TOKEN_BEGIN = 'AUTH_REFRESH_TOKEN_BEGIN';
export const AUTH_REFRESH_TOKEN_SUCCESS = 'AUTH_REFRESH_TOKEN_SUCCESS';
export const AUTH_REFRESH_TOKEN_FAILURE = 'AUTH_REFRESH_TOKEN_FAILURE';
export const AUTH_REFRESH_TOKEN_DISMISS_ERROR = 'AUTH_REFRESH_TOKEN_DISMISS_ERROR';

export function useRefreshToken() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => { 
      //dispatch(refreshToken(...params));
      return refreshToken()(dispatch);
    },
    [dispatch]
  );
  return { refreshToken: boundAction };
}

export function refreshToken() {
  return async (dispatch) => {
    
    dispatch({
      type: AUTH_REFRESH_TOKEN_BEGIN,
    });

    try {
      let accessToken = await SerranaClient.refreshToken();

      try {
        dispatch({
          type: AUTH_REFRESH_TOKEN_SUCCESS,
          data: {
            initialAccessToken: undefined,
            accessToken,
            accessTokenData: jwt.decode(accessToken)
          },
        });
      }
      catch (error) {
        console.log("Error after AUTH_REFRESH_TOKEN_SUCCESS:", error);
      }

      return accessToken;
    }
    catch (err) {
      localStorage.removeItem('accessToken');

      dispatch({
        type: AUTH_REFRESH_TOKEN_FAILURE,
        data: { error: err },
      });
      
      throw err;
    }
  };
}

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export function dismissRefreshTokenError() {
  return {
    type: AUTH_REFRESH_TOKEN_DISMISS_ERROR,
  };
}

export function reducer(state, { type, data}) {
  switch (type) {
    case AUTH_REFRESH_TOKEN_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        refreshTokenPending: true,
        refreshTokenError: null,
      };

    case AUTH_REFRESH_TOKEN_SUCCESS:
      // The request is success
      const { accessToken, accessTokenData } = data;

      return {
        ...state,
        accessToken,
        accessTokenData,
        refreshTokenPending: false,
        refreshTokenError: null,
      };

    case AUTH_REFRESH_TOKEN_FAILURE:
      // The request is failed
      
      return {
        ...state,
        initialAccessToken: undefined,
        refreshTokenPending: false,
        refreshTokenError: data.error,
      };

    case AUTH_REFRESH_TOKEN_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        refreshTokenError: null,
      };

    default:
      return state;
  }
}