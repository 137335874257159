import { useState, useEffect } from 'react';

export const useFetch = (fetchFunc, initialValue) => {
    const [ data, setData ] = useState(initialValue);
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(undefined); 

    const refresh = async () => {
        try {
            setLoading(true);
            setData(await fetchFunc());
            setError(undefined);
            setLoading(false);
        } catch (e) {
            console.log(e)
            setLoading(false);
            setError(e);
        }
    }

    useEffect(() => { 
        refresh();
    }, []);
    
    return [
        data,
        loading,
        error,
        refresh,
        setData,
        setLoading,
        setError,
    ];
}

export const useSimpleFetch = (fetchFunc) => {
    const { data, setData } = useFetch(fetchFunc, []);
    return [data, setData]
}