import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import AppSecretField from '../common/AppSecretField';
import { AppTextField } from '../common';
import Link from '../common/Link';
import { minLength, composeValidators, required, isEmail } from '../../common/validators';

const useStyles = makeStyles(theme => ({
  fieldsDiv: {
    paddingBottom: theme.spacing(4)
  },
  backButton: {
    marginLeft: 'auto',
  }
}));


function PreCadastroForm({
  onSubmit,
  validate
}) {
  const classes = useStyles();

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, pristine, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Card className={classes.card}>
            <CardHeader 
              title={
                <Typography component="h5" style={{fontWeight: 500}}>
                  Cadastrar
                </Typography>
              }
              style={{
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '20px',
                letterSpacing: '-0.05px',
              }} />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field 
                    name="nome" 
                    component={AppTextField}
                    label="Nome"
                    validate={required}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field 
                    name="email" 
                    component={AppTextField}
                    label="E-mail"
                    validate={composeValidators(required, isEmail)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field 
                    name="senha" 
                    component={AppSecretField}
                    label="Senha"
                    validate={composeValidators(required, minLength(6))}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field 
                    name="confirmaSenha" 
                    component={AppSecretField}
                    label="Confirmar Senha"
                    validate={composeValidators(required, minLength(6))}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions disableSpacing>
              <Button type="submit" variant="contained" color="primary" className={classes.button} disabled={pristine || submitting}>
                Cadastrar
              </Button>
              <Button 
                className={classes.backButton}
                variant="contained" 
                color="inherit" 
                component={Link} to="/"
              >
                Voltar
            </Button>
            </CardActions>
          </Card>
        </form>
      )}
    />
  );
}

PreCadastroForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default PreCadastroForm;
