import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import SerranaClient from '../../../clients/serrana';

import {
  FRETE_GET_VEICULO_CARROCERIAS_BEGIN,
  FRETE_GET_VEICULO_CARROCERIAS_SUCCESS,
  FRETE_GET_VEICULO_CARROCERIAS_FAILURE,
  FRETE_GET_VEICULO_CARROCERIAS_DISMISS_ERROR,
} from './constants';


export function useGetVeiculoCarrocerias() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => { 
      return getVeiculoCarrocerias(...params)(dispatch);
    },
    [dispatch]
  );
  return { getVeiculoCarrocerias: boundAction };
}

export function getVeiculoCarrocerias() {
  return async (dispatch) => {
    
    dispatch({
      type: FRETE_GET_VEICULO_CARROCERIAS_BEGIN,
    });

    try {
      let veiculoCarrocerias = await SerranaClient.getVeiculoCarrocerias();
      
      dispatch({
        type: FRETE_GET_VEICULO_CARROCERIAS_SUCCESS,
        data: veiculoCarrocerias,
      });

      return veiculoCarrocerias;
    }
    catch (err) {
      dispatch({
        type: FRETE_GET_VEICULO_CARROCERIAS_FAILURE,
        data: { error: err },
      });
      
      throw err;
    }
  };
}

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export function dismissGetVeiculoCarroceriasError() {
  return {
    type: FRETE_GET_VEICULO_CARROCERIAS_DISMISS_ERROR,
  };
}

const initialState = {
  fretes: []
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case FRETE_GET_VEICULO_CARROCERIAS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getVeiculoCarroceriasPending: true,
        getVeiculoCarroceriasError: null,
      };

    case FRETE_GET_VEICULO_CARROCERIAS_SUCCESS:
      // The request is success
      return {
        ...state,
        veiculoCarrocerias: action.data,
        getVeiculoCarroceriasPending: false,
        getVeiculoCarroceriasError: null,
      };

    case FRETE_GET_VEICULO_CARROCERIAS_FAILURE:
      // The request is failed
      return {
        ...state,
        getVeiculoCarroceriasPending: false,
        getVeiculoCarroceriasError: action.data.error,
      };

    case FRETE_GET_VEICULO_CARROCERIAS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getVeiculoCarroceriasError: null,
      };

    default:
      return state;
  }
}