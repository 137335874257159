import React from 'react';
import { isFragment } from 'react-is';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';

import PopupState, { bindTrigger, bindPopper } from 'material-ui-popup-state'

export default function AppButtonGroup({
  buttons,
  menuItens,
  PopperIcon = MoreVertIcon
}) {
  const renderMenuItens = (popupState) => {
    let itens = menuItens(popupState);
    // avoid https://github.com/mui-org/material-ui/issues/16181
    if (isFragment(itens)) {
      itens = itens.props.children;
    }
    return itens;
  }

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <PopupState variant="popover">
          {popupState => (
            <>
              <ButtonGroup variant="contained" color="primary" ref={popupState.anchorRef} aria-label="split button">
                { buttons }
                <Button
                  color="primary"
                  size="small"
                  {...bindTrigger(popupState)}
                >
                  <PopperIcon />
                </Button>
              </ButtonGroup>
                <Popper {...bindPopper(popupState)} transition>
                  {({ TransitionProps, placement }) => (
                    <ClickAwayListener onClickAway={() => { popupState.close() }}>
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                      >
                        <Paper>
                            <MenuList id="split-button-menu">
                              { renderMenuItens(popupState) }
                            </MenuList>
                        </Paper>
                      </Grow>
                    </ClickAwayListener>
                  )}
                </Popper>
            </>
          )
        }
        </PopupState>
      </Grid>
    </Grid>
  );
}