import React from 'react';
import { connect } from 'react-redux';

import parseIsoDate from 'date-fns/parseISO';
import formatDate from 'date-fns/format';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';

import { useGetSuporteChamados } from './redux/getSuporteChamados';
import { useAddErrorMessage } from '../layout/redux/addSnackMessage';


import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Link from '../common/Link';
import SerranaLayout from '../layout/SerranaLayout';
import { getChamadoLabelBySituacao } from './suporte-utils';



const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  progressDiv: {
    flexGrow: 1,
  },
}));

const fetchChamados = () => {
  const { addErrorMessage } = useAddErrorMessage();
  const { getSuporteChamados } = useGetSuporteChamados();

  const doFetchChamados = async () => {
      try {
        await getSuporteChamados();
      }
      catch (e) {
        addErrorMessage(e.message);
      }
  }

  React.useEffect(() => {
      doFetchChamados();
  }, []);

  return {doFetchChamados};
}

const ChamadosListPage = ({
  suporte: {
    getSuporteChamadosPending,
    getSuporteChamadosError,
    chamados,
  }
}) => {
  const classes = useStyles();
  const { doFetchChamados } = fetchChamados();

  return (
    <SerranaLayout>
      <Grid container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={3}
          style={{
            marginBottom: 2,
            marginTop: 2,
          }}
        >
        <Grid item>
          <Typography component="h1" variant="h3" style={{
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '28px',
              letterSpacing: '-0.06px',
            }}>
            Chamados
          </Typography>
        </Grid>
        <Grid item>
          <Button 
            variant="contained" 
            color="primary" 
            className={classes.button}
            component={Link} to="/suporte/new"
            >
            Novo Chamado
          </Button>
        </Grid>
      </Grid>
      <Card>
        <CardHeader 
          title={
            <Typography component="h5" style={{fontWeight: 500}}>
              Meus chamados
            </Typography>
          }
          action={
            <IconButton aria-label="refresh" title="Atualizar lista" onClick={(evt) => { doFetchChamados(); }} style={{ padding: '3px', marginRight: '5px' }}>
              <RefreshIcon />
            </IconButton>
          }
          style={{
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.05px',
          }} />
        <Divider/>
        {getSuporteChamadosPending &&
        <div className={classes.progressDiv}>
          <LinearProgress />
        </div>
        }
        <Table className={classes.table}>
          <TableHead bgcolor="#fafafa">
            <TableRow>
              <TableCell>Data de Abertura</TableCell>
              <TableCell>Data de Fechamento</TableCell>
              <TableCell>Título</TableCell>
              <TableCell>Situação</TableCell>
              <TableCell>Ação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chamados && chamados.map(chamado => (
              <TableRow key={chamado.id}>
                <TableCell component="th" scope="row">
                {formatDate(parseIsoDate(chamado.dataAbertura), "dd/MM/yyyy HH:mm:ss")}
                </TableCell>
                <TableCell component="th" scope="row">
                  {chamado.dataFechamento && formatDate(parseIsoDate(chamado.dataFechamento), "dd/MM/yyyy HH:mm:ss")}
                </TableCell>
                <TableCell>{chamado.titulo}</TableCell>
                <TableCell>{getChamadoLabelBySituacao(chamado.situacao)}</TableCell>
                <TableCell>
                  <Button 
                    component={Link}
                    to={`/suporte/chamado/${chamado.id}`}
                    variant="outlined" 
                    color="primary" 
                    className={classes.button}
                  >
                    Visualizar
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </SerranaLayout>
  );
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    suporte: state.suporte,
  };
}

export default connect(
  mapStateToProps
)(ChamadosListPage);
