import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { useAddErrorMessage, useAddSuccessMessage } from '../layout/redux/addSnackMessage';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import SerranaMessages from '../layout/SerranaMessages';
import { PreCadastroForm } from '.';
import SerranaApi from '../../clients/serrana';
import { usePush } from '../../common/redux-utils';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const PreCadastroPage = (props) => {
  const classes = useStyles();

  const { addErrorMessage } = useAddErrorMessage();
  const { addSuccessMessage } = useAddSuccessMessage();
  const { push } = usePush();

  const onSubmit = async (values) => {
    const { nome, email, senha, confirmaSenha } = values;
    try {
      await SerranaApi.preCadastrar(nome, email, senha, confirmaSenha);
      addSuccessMessage("Cadastro realizado com sucesso!");
      push("/");
    }
    catch (e) {
      addErrorMessage(e.message);
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <div>
          <img src={require('../../images/logo.png')} alt="Serrana" />
        </div>
        <PreCadastroForm onSubmit={onSubmit} />
      </div>
      <SerranaMessages />
    </Container>
  );
}

PreCadastroPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  staticContext: PropTypes.object,
};

export default PreCadastroPage;
