import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import SerranaDrawer, { DRAWER_WIDTH } from './SerranaDrawer';
import SerranaAppBar from './SerranaAppBar';
import SerranaMessages from './SerranaMessages';
import { SnackbarMessage, Link } from '../common';
import { useLocation } from 'react-router-dom';
import { useHasRoles } from '../auth/auth-hooks';
import { ROLE_PRE_CADASTRO, ROLE_CADASTRO_PENDENTE } from '../../common/auth';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));



const SerranaLayout = (props) => {
  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { pathname } = useLocation();
  const { hasRoles} = useHasRoles();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <SerranaAppBar onClick={handleDrawerToggle} />
      <SerranaDrawer mobileOpen={mobileOpen} onCloseHandler={handleDrawerToggle} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {
          (hasRoles(ROLE_PRE_CADASTRO) && pathname !== "/conta/cadastro") &&
          <SnackbarMessage 
            variant='warning' 
            message="Finalize seu cadastro para liberar todas as funcionalidades do sistema."
            action={
              <Button 
                variant="contained" 
                component={Link} 
                to="/conta/cadastro"
              >
                Cadastro
              </Button>
            }
          />
        }
        {
          hasRoles(ROLE_CADASTRO_PENDENTE) &&
          <SnackbarMessage 
            variant='info' 
            message="Seu cadastro está aguardando aprovação."
          />
        }
        {props.children}
      </main>
      <SerranaMessages/>
    </div>
  );
}



export default SerranaLayout;