import { useMemo, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

export function useActions(actions, deps) {
  const dispatch = useDispatch();
  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map(a => bindActionCreators(a, dispatch))
      }
      return bindActionCreators(actions, dispatch)
    },
    deps ? [dispatch, ...deps] : [dispatch]
  )
}

export function usePush() {
  const dispatch = useDispatch();
  const boundAction = useCallback(
    (...args) => {
      dispatch(push(...args))
    },
    [dispatch]
  );
  return { push: boundAction };
}