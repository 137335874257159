import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { useSavePostLoginUrl } from './redux/savePostLoginUrl';
import { useAddErrorMessage } from '../layout/redux/addSnackMessage';
import { AUTH_ANONYMOUS, AUTH_AUTHENTICATED } from '../../common/auth';

const RedirectToIndex = ({
  urlOriginal,
  errorMessage
}) => {
  console.log('urlOriginal', urlOriginal);
  const { savePostLoginUrl } = useSavePostLoginUrl();
  const { addErrorMessage } = useAddErrorMessage();
  if (urlOriginal) {
    savePostLoginUrl(urlOriginal);
  }
  if (errorMessage) {
    addErrorMessage(errorMessage);
  }

  return (
    <Redirect to='/' />
  )
}

const hasAllRoles = (auth, roles) => {
  return auth.accessTokenData && roles.every(role => auth.accessTokenData.roles.includes(role));
}

const isAuthed = (auth) => {
  return auth.accessToken !== undefined;
}

const getComponentDisplayName = (WrappedComponent) => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

const SecureRoute = ({ auth, router, component: Component, roles, ...other }) => {
  if (roles === undefined) {
    throw new Error("Bad route: "+router.location.pathname+" component: "+getComponentDisplayName(Component));
  }
  if (roles === AUTH_ANONYMOUS) {
    return (
      <Route component={Component} {...other} />
    );
  }
  else {
    if (isAuthed(auth)) {
      return (
        <Route 
          {...other} 
          render={(props) => (
            (roles === AUTH_AUTHENTICATED || hasAllRoles(auth, roles)) ? 
            <Component {...props} />
            :
            <RedirectToIndex errorMessage="Acesso negado." />
          )} 
        />
      );

    }
    else {
      return (
        <RedirectToIndex urlOriginal={router.location.pathname} />
      );
    }
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    auth: state.auth,
    router: state.router
  };
}

export default connect(
  mapStateToProps
)(SecureRoute);
