import React from 'react';

import SerranaLayout from '../layout/SerranaLayout';

import ChamadoForm from './ChamadoForm';
import SerranaApi from '../../clients/serrana';
import { useAddErrorMessage, useAddSuccessMessage } from '../layout/redux/addSnackMessage';
import { usePush } from '../../common/redux-utils';



function NovoChamadoPage() {
  const { addErrorMessage } = useAddErrorMessage();
  const { addSuccessMessage } = useAddSuccessMessage();
  const { push } = usePush();

  const onSubmit = async (values) => {
    try {
      await SerranaApi.cadastrarSuporteChamado(values);
      addSuccessMessage("Chamado criado com sucesso!");
      push("/suporte");
    }
    catch (e) {
      addErrorMessage(e.message);
    }
  }

  return (
    <SerranaLayout>
      <ChamadoForm onSubmit={onSubmit}/>
    </SerranaLayout>
  );
}

export default NovoChamadoPage;
