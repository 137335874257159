import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { AppDropzone } from '.';
import { useForm, Field } from 'react-final-form';

function AppDropzoneArquivoField({
  name,
  initialArquivo,
  onDropRejected,
  dropzoneText = "Clique ou arraste os arquivos aqui.",
  ...other
}) {
  const form = useForm();
  const [arquivo, setArquivo] = React.useState(initialArquivo);

  React.useEffect(() => {
    form.change(name, arquivo);
    form.blur(name);
  }, [arquivo]);

  const handleUploadSuccess = (arquivo) => {
    setArquivo(arquivo);
  }

  const handleUploadDeleted = (arquivo) => {
    setArquivo(null);
  }

  return (
    <Field name={name} {...other} filesLimit={1}>
      {fieldProps => {
        return (
          <AppDropzone
            arquivos={arquivo ? [arquivo] : []}
            onDropRejected={onDropRejected}
            onUploadSuccess={handleUploadSuccess}
            onUploadDeleted={handleUploadDeleted}
            dropzoneText={dropzoneText}
            {...fieldProps}
          />
        );
      }}
    </Field>
  );
}

AppDropzoneArquivoField.propTypes = {
  name: PropTypes.string.isRequired,
  initialArquivos: PropTypes.array,
  onDropRejected: PropTypes.func,
  dropzoneText: PropTypes.string,
};

export default AppDropzoneArquivoField;
