import _ from 'lodash';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import SerranaClient from '../../../clients/serrana';

import {
  FRETE_APROVAR_FRETE_BEGIN,
  FRETE_APROVAR_FRETE_SUCCESS,
  FRETE_APROVAR_FRETE_FAILURE,
  FRETE_APROVAR_FRETE_DISMISS_ERROR,
} from './constants';


export function useAprovarFrete() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => {
      return aprovarFrete(...params)(dispatch);
    },
    [dispatch]
  );
  return { aprovarFrete: boundAction };
}

export function aprovarFrete(freteId) {
  return async (dispatch) => {
    
    dispatch({
      type: FRETE_APROVAR_FRETE_BEGIN,
    });

    try {
      let freteAprovado = await SerranaClient.aprovarFrete(freteId);
      dispatch({
        type: FRETE_APROVAR_FRETE_SUCCESS,
        data: {
          freteAprovado
        },
      });
      dispatch(push(`/frete/${freteId}`));

      return freteAprovado;
    }
    catch (err) {
      dispatch({
        type: FRETE_APROVAR_FRETE_FAILURE,
        data: { error: err },
      });
      
      throw err;
    }
  };
}

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export function dismissAprovarFreteError() {
  return {
    type: FRETE_APROVAR_FRETE_DISMISS_ERROR,
  };
}

export function reducer(state, { type, data }) {
  switch (type) {
    case FRETE_APROVAR_FRETE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        aprovarFretePending: true,
        aprovarFreteError: null,
      };

    case FRETE_APROVAR_FRETE_SUCCESS:
      // The request is success
      const { freteAprovado } = data;

      // atualizar listagem de fretes imediatamente
      return _.set({
        ...state,
        aprovarFretePending: false,
        aprovarFreteError: null,
      }, `fretes[${freteAprovado.id}]`, freteAprovado);

    case FRETE_APROVAR_FRETE_FAILURE:
      // The request is failed
      return {
        ...state,
        aprovarFretePending: false,
        aprovarFreteError: data.error,
      };

    case FRETE_APROVAR_FRETE_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        aprovarFreteError: null,
      };

    default:
      return state;
  }
}