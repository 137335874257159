import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import SerranaClient from '../../../clients/serrana';

import {
  FRETE_GET_FRETES_BEGIN,
  FRETE_GET_FRETES_SUCCESS,
  FRETE_GET_FRETES_FAILURE,
  FRETE_GET_FRETES_DISMISS_ERROR,
} from './constants';


export function useGetFretes() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => {
      return getFretes(...params)(dispatch);
    },
    [dispatch]
  );
  return { getFretes: boundAction };
}

export function getFretes(currentPage, pageSize) {
  return async (dispatch) => {
    
    dispatch({
      type: FRETE_GET_FRETES_BEGIN,
    });

    try {
      let fretes = await SerranaClient.getFretes(currentPage, pageSize);
      dispatch({
        type: FRETE_GET_FRETES_SUCCESS,
        data: {
          fretes
        },
      });

      return fretes;
    }
    catch (err) {
      dispatch({
        type: FRETE_GET_FRETES_FAILURE,
        data: { error: err },
      });
      
      throw err;
    }
  };
}

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export function dismissGetFretesError() {
  return {
    type: FRETE_GET_FRETES_DISMISS_ERROR,
  };
}

export function reducer(state, { type, data }) {
  switch (type) {
    case FRETE_GET_FRETES_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getFretesPending: true,
        getFretesError: null,
      };

    case FRETE_GET_FRETES_SUCCESS:
      // The request is success
      const { fretes } = data;
      return {
        ...state,
        fretes,
        getFretesPending: false,
        getFretesError: null,
      };

    case FRETE_GET_FRETES_FAILURE:
      // The request is failed
      return {
        ...state,
        getFretesPending: false,
        getFretesError: data.error,
      };

    case FRETE_GET_FRETES_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getFretesError: null,
      };

    default:
      return state;
  }
}