import React from 'react';
import PropTypes from 'prop-types';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

function AppListMenuItem({
  button = true,
  icon,
  text,
  component,
  to,
}) {

  return (
    <ListItem button={button} component={component} to={to}>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
}

AppListMenuItem.propTypes = {
  button: PropTypes.bool,
  icon: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
  component: PropTypes.elementType,
  to: PropTypes.string,
};

export default AppListMenuItem;
