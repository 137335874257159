import _ from 'lodash';

export const required = value => (value ? undefined : 'Campo obrigatório')

export const isNumber = value => (isNaN(value) ? 'Deve ser um número' : undefined)

export const minValue = min => value =>
  isNaN(value) || value >= min ? undefined : `Valor deve ser no mínimo ${min}`

export const minLength = min => value => _.get(value, 'length', 0) >= min ? undefined : `Deve conter ${min} caracteres ou mais`

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)

export const containsStr = char => value => value.includes(char) ? undefined : `Deve conter o caractere '${char}'`

export const isEmail = value => composeValidators(minLength(3), containsStr("@"))(value) ? "Deve ser um endereço de e-mail válido" : undefined

export const isCpf = value => value.replace(/\D/g,'').length === 11 ? undefined : 'Deve ser um CPF válido';

export const isCnpj = value => value.replace(/\D/g,'').length === 14 ? undefined : 'Deve ser um CNPJ válido';

export const isCpfOrCnpj = value => (value.replace(/\D/g,'').length === 11 || value.replace(/\D/g,'').length === 14) ? undefined : 'Deve ser um CPF ou CNPJ válido';