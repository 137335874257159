import React from 'react';
import ContentLoader from 'react-content-loader'

export default function LoadingWrapper({
  loading, Component, PlaceHolder, 
  height, width, 
  children
}) {

  if (loading) {
    return <PlaceHolder height={height} width={width} />;
  }
  else {
    return children;
  }
}

export const TextLabelPlaceholder = ({height, width}) => (
  <ContentLoader 
    speed={2}
    height={height}
    width={width}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
     <rect x="0" y="0" rx={1} ry={1} width={width*0.9} height={height*0.9} />
  </ContentLoader>
)

export const ListIconPlaceHolder = ({height, width}) => (
  <ContentLoader 
    height={height}
    width={width}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <circle cx="22" cy="22" r="20" /> 
    <rect x="50" y="0" rx="5" ry="5" width={width*0.9} height="16" /> 
    <rect x="50" y="25" rx="5" ry="5" width={width*0.9} height="16" />
  </ContentLoader>
)