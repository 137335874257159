import React from 'react';

import TextField from '@material-ui/core/TextField';

export default function AppTextField({
  label,
  placeholder,
  input,
  meta: { touched, invalid, error },
  ...custom
}) { 
  return (
    <TextField
      fullWidth
      label={label}
      placeholder={placeholder ? placeholder : label}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
    />
  )
}
