import SerranaClient from '../../../clients/serrana';
import { push } from 'connected-react-router';
import { logout } from '../../auth/redux/logout.js'

import {
  FRETE_CADASTRAR_FRETE_BEGIN,
  FRETE_CADASTRAR_FRETE_SUCCESS,
  FRETE_CADASTRAR_FRETE_FAILURE,
  FRETE_CADASTRAR_FRETE_DISMISS_ERROR,
} from './constants';

export function cadastrarFrete(frete) {
  return async (dispatch) => {
    
    dispatch({
      type: FRETE_CADASTRAR_FRETE_BEGIN,
    });

    try {
      let res = await SerranaClient.cadastrarFrete(frete);
      dispatch({
        type: FRETE_CADASTRAR_FRETE_SUCCESS,
        data: res.data.token,
      });

      dispatch(push('/frete'));

      return res;
    }
    catch (err) {
      if (err.tokenExpired) {
        console.log("LOG OUT!");
        dispatch(logout());
      }
      else {
        dispatch({
          type: FRETE_CADASTRAR_FRETE_FAILURE,
          data: { error: err },
        });
      }
      
      throw err;
    }
  };
}

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export function dismissCadastrarFreteError() {
  return {
    type: FRETE_CADASTRAR_FRETE_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FRETE_CADASTRAR_FRETE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        loginPending: true,
        loginError: null,
      };

    case FRETE_CADASTRAR_FRETE_SUCCESS:
      // The request is success
      console.log(action.data);
      return {
        ...state,
        loginPending: false,
        loginError: null,
      };

    case FRETE_CADASTRAR_FRETE_FAILURE:
      // The request is failed
      return {
        ...state,
        loginPending: false,
        loginError: action.data.error,
      };

    case FRETE_CADASTRAR_FRETE_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        loginError: null,
      };

    default:
      return state;
  }
}