import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  }
}));

export default function LoadingWrapper({loading, progressSize = 24, children}) {
  const classes = useStyles();

  const progressStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -progressSize/2,
    marginLeft: -progressSize/2,
  };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        {children}
        {loading && <CircularProgress size={progressSize} style={progressStyle}/>}
      </div>
    </div>
  );
}
