import React from 'react';
import PropTypes from 'prop-types';
import { useForm, Field } from 'react-final-form';
import { DropzoneArea } from 'material-ui-dropzone';

function AppDropzoneField({
  name,
  ...other
}) {
  const form = useForm();

  const handleChange = (files) => {
    form.change(name, files);
    form.blur(name);
  }

  return (
    <Field name={name} {...other}>
      {fieldProps => {
        return (
          <DropzoneArea
            onChange={handleChange}
            dropzoneText="Clique ou arraste os arquivos de Nota Fiscal aqui."
            {...fieldProps}
          />
        );
      }}
    </Field>
  );
}

AppDropzoneField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default AppDropzoneField;
