export const AUTH_ANONYMOUS = '@anonymous';

export const AUTH_AUTHENTICATED = '@authenticated';

export const ROLE_CLIENTE = 'ROLE_CLIENTE';
export const ROLE_PRE_CADASTRO = 'ROLE_PRE_CADASTRO';
export const ROLE_CADASTRO_PENDENTE = 'ROLE_CADASTRO_PENDENTE';
export const ROLE_CADASTRO_APROVADO = 'ROLE_CADASTRO_APROVADO';

export const ROLES = [
  ROLE_CLIENTE,
  ROLE_PRE_CADASTRO,
  ROLE_CADASTRO_PENDENTE,
  ROLE_CADASTRO_APROVADO,
]