import React from 'react';
import { Form, Field } from 'react-final-form';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Link from '../common/Link';

import AppTextField from '../common/AppTextField';
import { required } from '../../common/validators';

const useStyles = makeStyles(theme => ({
  fieldsDiv: {
    paddingBottom: theme.spacing(4)
  },
  maxWidth: {
    width: '100%',
  }
}));

const ChamadoForm = ({
  onSubmit
}) => {
  const classes = useStyles();

  return (
    <Form
      onSubmit={onSubmit}
    >
      {({handleSubmit, pristine, submitting}) => (
        <form onSubmit={handleSubmit}>
          <Grid container
              direction="row"
              justify="space-between"
              alignItems="center"
              spacing={3}
              style={{marginBottom: 2}}>
            <Grid item>
              <Typography component="h2" variant="overline">
                Cadastro
              </Typography>
              <Typography component="h1" variant="h3" style={{
                  fontSize: '24px',
                  fontWeight: 500,
                  lineHeight: '28px',
                  letterSpacing: '-0.06px',
                }}>
                Novo chamado
              </Typography>
            </Grid>
            <Grid item>
              <Button 
                variant="contained" 
                color="inherit" 
                className={classes.button}
                component={Link} to="/suporte"
                >
                Voltar
              </Button>
            </Grid>
          </Grid>
          <Card className={classes.card}>
            <CardHeader 
              title={
                <Typography component="h5" style={{fontWeight: 500}}>
                  Novo chamado
                </Typography>
              }
              style={{
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '20px',
                letterSpacing: '-0.05px',
              }} />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <Field 
                    name="titulo" 
                    component={AppTextField}
                    label="Título"
                    validate={required}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Field 
                    name="descricao" 
                    component={AppTextField}
                    multiline={true}
                    rows={8}
                    label="Descrição"
                    className={classes.maxWidth}
                    validate={required}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider/>
            <CardActions>
              <Button type="submit" variant="contained" color="primary" className={classes.button} disabled={pristine || submitting}>
                Cadastrar
              </Button>
            </CardActions>
          </Card>
        </form>
      )}
    </Form>
  );
}

export default ChamadoForm;