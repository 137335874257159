import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

import SerranaLayout from '../layout/SerranaLayout';
import { CadastroForm } from '.';
import { useAddErrorMessage, useAddSuccessMessage } from '../layout/redux/addSnackMessage';
import { usePush } from '../../common/redux-utils';
import SerranaApi from '../../clients/serrana';
import { useFetch } from '../../hooks/useFetch';
import { SnackbarMessage } from '../common';
import { useHasRoles } from '../auth/auth-hooks';

function CadastroPage() {
  const { addErrorMessage } = useAddErrorMessage();
  const { addSuccessMessage } = useAddSuccessMessage();
  const { push } = usePush();
  const { hasRoles } = useHasRoles();

  const readOnly = hasRoles('ROLE_CADASTRO_APROVADO');

  const onSubmit = async (values) => {
    try {
      await SerranaApi.cadastrarCliente(values);
      addSuccessMessage("Cadastro realizado com sucesso!");
      push("/");
    }
    catch (e) {
      addErrorMessage(e.message);
    }
  }

  const [me, loading, error] = useFetch(SerranaApi.getMe);

  let initialValues = undefined;
  if (me) {
    initialValues = me.dados;
    delete initialValues.id;
  }
  
  
  return (
    <SerranaLayout>
      <div className="conta-conta-page">
        <Grid container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={3}
          style={{
            marginBottom: 2,
            marginTop: 2,
          }}
        >
          <Grid item>
            <Typography component="h1" variant="h3" style={{
                fontSize: '24px',
                fontWeight: 500,
                lineHeight: '28px',
                letterSpacing: '-0.06px',
              }}>
              Cadastro
            </Typography>
          </Grid>
        </Grid>
      </div>
      {
        !me || loading ?
        (
          (loading) ?
          <div>
            <LinearProgress />
          </div>
          :
          <SnackbarMessage variant='error' message='Ocorreu um erro carregando seus dados. Tente novamente mais tarde.' />
        )
        :
        (
          <CadastroForm 
            initialValues={initialValues} 
            readOnly={readOnly}
            onSubmit={onSubmit} 
          />
        )
      }
    </SerranaLayout>
  );
}

CadastroPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  staticContext: PropTypes.object,
};

export default CadastroPage;
