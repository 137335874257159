import { FreteListPage, FreteNewPage, FreteViewPage } from './';
import FretePrintPage from './FretePrintPage';
import { ROLE_CADASTRO_APROVADO } from '../../common/auth';
// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html


export default {
  path: 'frete',
  childRoutes: [
    { path: 'index', component: FreteListPage, roles: ['ROLE_CLIENTE'] },
    { path: 'new', component: FreteNewPage, roles: [ROLE_CADASTRO_APROVADO]  },
    { path: 'copy/:freteId', component: FreteNewPage, roles: [ROLE_CADASTRO_APROVADO]  },
    { path: 'print/:freteId', component: FretePrintPage, roles: [ROLE_CADASTRO_APROVADO]  },
    { path: ':freteId', component: FreteViewPage, roles: ['ROLE_CLIENTE']  },
  ],
};
