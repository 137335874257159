import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import SerranaLayout from '../layout/SerranaLayout';
import LocalForm from './LocalForm';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '../common/Link';
import { useAddErrorMessage, useAddSuccessMessage } from '../layout/redux/addSnackMessage';
import { usePush } from '../../common/redux-utils';



export function LocalNewPage({
  actions
}) {
  const { addErrorMessage } = useAddErrorMessage();
  const { addSuccessMessage } = useAddSuccessMessage();
  const { push } = usePush();

  const onSubmit = async (values) => {
    try {
      await actions.cadastrarLocal(values);
      addSuccessMessage('Local cadastrado com sucesso!');
      push('/local');
    }
    catch (e) {
      addErrorMessage(e.message);
    }
  }

  return (
    <SerranaLayout>
      <Grid container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={3}
        style={{marginBottom: 2}}>
        <Grid item>
          <Typography component="h2" variant="overline">
            Cadastro
          </Typography>
          <Typography component="h1" variant="h3" style={{
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '28px',
              letterSpacing: '-0.06px',
            }}>
            Novo local
          </Typography>
        </Grid>
        <Grid item>
          <Button 
            variant="contained" 
            color="inherit" 
            component={Link} to="/local"
            >
            Voltar
          </Button>
        </Grid>
      </Grid>
      <LocalForm onSubmit={onSubmit} />
    </SerranaLayout>
  );
}

LocalNewPage.propTypes = {
  local: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    local: state.local,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocalNewPage);
