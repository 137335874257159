import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FreteRecusaForm from './FreteRecusaForm';


function FreteRecusaDialog({
  frete: { freteMotivos },
  actions,
  freteSelecionado,
  open,
  onClose,
  title = "Rejeitar frete?",
}) {
  
  React.useEffect(() => {
    actions.getFreteMotivos();
  }, []);

  const mapMotivoToOption = (motivo) => ({ label: motivo.nome, value: motivo.id });

  const motivoFreteStatusFilter = motivo => _.isEmpty(motivo.situacoes) || motivo.situacoes.includes(freteSelecionado.situacao);
  const motivos = freteMotivos.filter(motivoFreteStatusFilter).map(mapMotivoToOption);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <FreteRecusaForm
          motivos={motivos}
          frete={freteSelecionado}
          onCancel={onClose}
        />
      </DialogContent>
    </Dialog>
  );
}

FreteRecusaDialog.propTypes = {
  frete: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  freteSelecionado: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    frete: state.frete,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FreteRecusaDialog);
