import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import pLimit from 'p-limit';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';

import { AppDropzoneArquivosField } from '../common';
import SerranaClient from '../../clients/serrana';
import { useAddErrorMessage, useAddSuccessMessage } from '../layout/redux/addSnackMessage';

const singleExec = pLimit(1);

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
}));

function FreteNotaFiscalUploadFormRender({
  handleSubmit, 
  form, 
  submitting, 
  pristine, 
  values,
  autoSave,
  autoSaveDelay
}) {
  const classes = useStyles();
  const { addErrorMessage } = useAddErrorMessage();
  const { arquivos } = values;

  const autoSubmit = React.useMemo(() => {
    return _.debounce(() => singleExec(handleSubmit), autoSaveDelay);
  }, [handleSubmit, autoSaveDelay]);

  const handleDropRejected = (files) => {
    addErrorMessage(`Arquivo(s) não suportado(s): ${files.map(f => f.name).join(', ')}`)
  }

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Notas Fiscais:</label>
        <AppDropzoneArquivosField 
          name="arquivos" 
          initialArquivos={arquivos}
          acceptedFiles={['text/xml']}
          filesLimit={100}
          maxFileSize={5*1024*1024}
          onDropRejected={handleDropRejected}
        />
      </div>
      { autoSave ?
        (
          <OnChange name="arquivos">
            {(value, previous) => {
              autoSubmit();
            }}
        </OnChange>
        )
        :
        (
          <Button
            disabled={submitting || pristine}
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<SaveIcon />}
          >
            Salvar Notas Fiscais
          </Button>
        )
      }
    </form>
  )
}

function FreteNotaFiscalUploadForm({
  frete,
  autoSave = false,
  autoSaveDelay = 1000,
}) {

  const { addErrorMessage } = useAddErrorMessage();
  const { addSuccessMessage } = useAddSuccessMessage();
  
  const onSubmit = async values => {
    console.log("ON SUMBIT!", values)
    const { arquivos } = values;
    const arquivoIds = arquivos.map(arq => arq.id);
    try {
      await SerranaClient.freteAssociarArquivos(frete.id, arquivoIds);
      addSuccessMessage("Notas fiscais salvas com sucesso.")
    }
    catch (e) {
      addErrorMessage(e.message);
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        "arquivos": frete.arquivos || []
      }}
      component={FreteNotaFiscalUploadFormRender}
      autoSave={autoSave}
      autoSaveDelay={autoSaveDelay}
    />
  );
}

FreteNotaFiscalUploadForm.propTypes = {
  frete: PropTypes.object.isRequired,
  autoSave: PropTypes.bool,
  autoSaveDelay: PropTypes.number,
};

export default FreteNotaFiscalUploadForm;
