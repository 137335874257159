import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AppSelectInput from './AppSelectInput';

import { useGetCidadesByUf } from './redux/getCidadesByUf';
import LoadingWrapper from './LoadingWrapper';

const SelectCidade = ({
  uf,
  input,
  common,
  children,
  ...other
}) => {
  const { getCidadesByUf } = useGetCidadesByUf();

  React.useEffect(() => {
    if (uf) {
      console.log("NEW UF", uf);
      // verificar se UF já carregada
      if (!common.cidadesByUf[uf]) {
        getCidadesByUf(uf);
      }

      // limpar cidade atual
      if (input.onChange) {
        input.onChange(undefined);
      }
    }
  }, [uf]);



  const cidades = common.cidadesByUf[uf] || [];
  const options = cidades.map(c => ({ label: c.nome, value: c.id }));

  return (
    <LoadingWrapper loading={common.getCidadesByUfPending}>
      <AppSelectInput
        fullWidth
        options={options}
        input={input}
        {...other}
      />
    </LoadingWrapper>
  );
}

SelectCidade.propTypes = {
  uf: PropTypes.string
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}


export default connect(
  mapStateToProps
)(SelectCidade);
