import _ from 'lodash';
import React from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';

export default function AppDataTable(props) {
  let {
    tableClassName,
    tableHead,
    tableBody,
    collection,
    pagination,
    loading,
    renderRow,
    onChangePage,
    onChangeRowsPerPage
  } = props;

  if (tableBody === undefined) {
    tableBody = (
      <TableBody>
        {collection && collection.items && collection.items.map((row) => (renderRow(row)))}
      </TableBody>
    )
  }

  return (
    <>
      {loading &&
        <div>
          <LinearProgress />
        </div>
      }
      <Table className={tableClassName}>
        {_.isFunction(tableHead) ? tableHead(props) : tableHead}
        {_.isFunction(tableBody) ? tableBody(props) : tableBody}
      </Table>
      {loading &&
        <div>
          <LinearProgress />
        </div>
      }
      {
        collection && collection.items && pagination &&
        <TablePagination
          labelRowsPerPage='Itens por página:'
          labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} de ${count}`}
          rowsPerPageOptions={_.sortBy(_.uniq([5, 10, 25, pagination.pageSize]))}
          component="div"
          count={collection.totalItems}
          rowsPerPage={pagination.pageSize}
          page={pagination.currentPage}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      }
    </>
  );
}