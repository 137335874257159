// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { ContaPage } from './';
import { AUTH_ANONYMOUS } from '../../common/auth';


import { PreCadastroPage } from './';

import { CadastroPage } from './';

export default {
  path: 'conta',
  childRoutes: [
    { path: 'index', component: CadastroPage, roles: ['ROLE_CLIENTE'] },
    { path: 'alterar-senha', component: ContaPage, roles: ['ROLE_CLIENTE'] },
    { path: 'precadastro', component: PreCadastroPage, roles: AUTH_ANONYMOUS },
    { path: 'cadastro', component: CadastroPage, roles: ['ROLE_CLIENTE'] },
  ],
};
