import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import SerranaClient from '../../../clients/serrana';

import {
  SUPORTE_GET_SUPORTE_CHAMADOS_BEGIN,
  SUPORTE_GET_SUPORTE_CHAMADOS_SUCCESS,
  SUPORTE_GET_SUPORTE_CHAMADOS_FAILURE,
  SUPORTE_GET_SUPORTE_CHAMADOS_DISMISS_ERROR,
} from './constants';


export function useGetSuporteChamados() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => {
      return getSuporteChamados()(dispatch);
    },
    [dispatch]
  );
  return { getSuporteChamados: boundAction };
}

export function getSuporteChamados() {
  return async (dispatch) => {
    
    dispatch({
      type: SUPORTE_GET_SUPORTE_CHAMADOS_BEGIN,
    });

    try {
      let chamados = await SerranaClient.getSuporteChamados();
      dispatch({
        type: SUPORTE_GET_SUPORTE_CHAMADOS_SUCCESS,
        data: {
          chamados
        },
      });

      return chamados;
    }
    catch (err) {
      dispatch({
        type: SUPORTE_GET_SUPORTE_CHAMADOS_FAILURE,
        data: { error: err },
      });
      
      throw err;
    }
  };
}

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export function dismissGetSuporteChamadosError() {
  return {
    type: SUPORTE_GET_SUPORTE_CHAMADOS_DISMISS_ERROR,
  };
}

export function reducer(state, { type, data }) {
  switch (type) {
    case SUPORTE_GET_SUPORTE_CHAMADOS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getSuporteChamadosPending: true,
        getSuporteChamadosError: null,
      };

    case SUPORTE_GET_SUPORTE_CHAMADOS_SUCCESS:
      // The request is success
      const { chamados } = data;
      return {
        ...state,
        chamados,
        getSuporteChamadosPending: false,
        getSuporteChamadosError: null,
      };

    case SUPORTE_GET_SUPORTE_CHAMADOS_FAILURE:
      // The request is failed
      return {
        ...state,
        getSuporteChamadosPending: false,
        getSuporteChamadosError: data.error,
      };

    case SUPORTE_GET_SUPORTE_CHAMADOS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getSuporteChamadosError: null,
      };

    default:
      return state;
  }
}