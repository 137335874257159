export const CHAMADO_SITUACAO_ABERTO  = 'aberto';
export const CHAMADO_SITUACAO_FECHADO = 'fechado';

export const CHAMADO_SITUACAO_LABELS = {
  'aberto': 'Aberto',
  'fechado': 'Fechado',
}

export const getChamadoLabelBySituacao = (situacao) => {
  return CHAMADO_SITUACAO_LABELS[situacao] ? CHAMADO_SITUACAO_LABELS[situacao] : situacao
}