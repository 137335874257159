import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';

import { makeStyles } from '@material-ui/core/styles';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { LocalNewDialog } from '.';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export function LocalMultiSelectDialog({
  actions,
  local: { locais, getLocaisPending },
  open,
  onClose,
  selected,
  onSelect,
  onDeselect,
  title = 'Selecione os locais',
}) {
  const classes = useStyles();

  const [localNewDialogOpen, setLocalNewDialogOpen] = React.useState(false);

  const handleLocalNewDialogClose = () => { 
    actions.getLocais(); 
    setLocalNewDialogOpen(false); 
    console.log('handleLocalNewDialogClose');
  }

  React.useEffect(() => {
    if (open) {
      actions.getLocais();
    }
  }, [open]);

  const isLocalSelected = (local) => !!(selected && (selected.find((l) => (l.id === local.id)) !== undefined));

  const handleToggle = local => () => {
    if (isLocalSelected(local)) {
      onDeselect(local);
    }
    else {
      onSelect(local);
    }
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      <DialogContent>
        { getLocaisPending && <div><LinearProgress /></div>}
        { !getLocaisPending && _.isEmpty(locais.items) && <div>Nenhum local encontrado.</div>}
        <Paper>
          <List className={classes.root}>
            {locais && locais.items && locais.items.map((local) => {
              const labelId = `checkbox-list-label-${local.id}`;

              return (
                <ListItem key={local.id} role={undefined} dense button onClick={handleToggle(local)}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={isLocalSelected(local)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText 
                    primary={local.nome} 
                    secondary={local.endereco.cidadeNome + ', ' + local.endereco.uf + ' - ' + local.endereco.cep} 
                  />
                </ListItem>
              );
            })}
          </List>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          startIcon={<AddLocationIcon />}
          onClick={() => { setLocalNewDialogOpen(true); }}
        >
          Cadastrar local
        </Button>
        <Button 
          variant="contained" 
          color="primary"
          onClick={onClose}
          >
          OK
        </Button>
      </DialogActions>
      <LocalNewDialog open={localNewDialogOpen} onClose={handleLocalNewDialogClose} />
    </Dialog>
  );
}

LocalMultiSelectDialog.propTypes = {
  local: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func.isRequired,
  onDeselect: PropTypes.func.isRequired,
  title: PropTypes.string,
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    local: state.local,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocalMultiSelectDialog);
