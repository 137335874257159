import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import TextField from '@material-ui/core/TextField';
import { MASK_CPF, MASK_CNPJ } from '../../common/utils';

function CpfCnpjInputComponent(props) {
  const { inputRef, onChange, onBlur, value, onModoChanged, ...other } = props;

  const [modo, setModo] = React.useState(undefined);

  React.useEffect(() => {
    if (_.isFunction(onModoChanged)) {
      onModoChanged(modo);
    }
  }, [modo]);
  

  const onBlurHandler = (evt) => {
    const value = evt.target.value.replace(/\D/g,'');
    if (value.length === 11) {
      setModo('cpf');
    }
    else if (value.length === 14) {
      setModo('cnpj');
    }
    else {
      setModo(undefined);
    }
    onBlur(evt);
  }

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        })
      }}
      allowLeadingZeros={true}
      onBlur={onBlurHandler}
      value={value}
      format={value.length <= 11 ? MASK_CPF+'#' : MASK_CNPJ}
      isNumericString
    />
  );
}

function AppCpfCnpjTextField({
  onModoChanged,
  label = "CPF/CNPJ",
  placeholder,
  input,
  meta: { touched, invalid, error },
  ...custom
}) { 

  const [modo, setModo] = React.useState(undefined);

  React.useEffect(() => {
    if (_.isFunction(onModoChanged)) {
      onModoChanged(modo);
    }
  }, [modo]);

  return (
    <TextField
      fullWidth
      label={modo ? (modo === 'cpf' ? 'CPF' : 'CNPJ') : label} 
      placeholder={placeholder ? placeholder : label}
      error={touched && invalid}
      helperText={touched && error}
      InputProps={{
        inputComponent: CpfCnpjInputComponent,
      }}
      inputProps={{
        onModoChanged: setModo
      }}
      {...input}
      {...custom}
    />
  )
}

AppCpfCnpjTextField.propTypes = {
  onModoChanged: PropTypes.func,
};

export default AppCpfCnpjTextField;
