import { PageNotFound } from '../features/common';
import homeRoute from '../features/home/route';
import commonRoute from '../features/common/route';
import _ from 'lodash';
import authRoute from '../features/auth/route';
import freteRoute from '../features/frete/route';
import layoutRoute from '../features/layout/route';
import contaRoute from '../features/conta/route';
import suporteRoute from '../features/suporte/route';
import localRoute from '../features/local/route';
import { AUTH_ANONYMOUS, AUTH_AUTHENTICATED } from './auth';

// NOTE: DO NOT CHANGE the 'childRoutes' name and the declaration pattern.
// This is used for Rekit cmds to register routes config for new features, and remove config when remove features, etc.
const childRoutes = [
  homeRoute,
  commonRoute,
  authRoute,
  freteRoute,
  layoutRoute,
  contaRoute,
  suporteRoute,
  localRoute,
];

const SPECIAL_ROLES = [AUTH_ANONYMOUS, AUTH_AUTHENTICATED];

const checkBadRoutes = (childRoutes, basePath) => {
  let badRoutes = childRoutes.filter(r => (r.component && _.isEmpty(r.childRoutes) && !(_.isArray(r.roles) || SPECIAL_ROLES.includes(r.roles))));
  badRoutes = badRoutes.map(r => (basePath+'/'+r.path));

  for (let route of childRoutes) {
    const subPath = route.path !== '' ? (basePath+'/'+route.path) : basePath;
    if (_.isArray(route.childRoutes)) {
      badRoutes = [...badRoutes, ...checkBadRoutes(route.childRoutes, subPath)];
    }
  }

  return badRoutes;
}

const badRoutes = checkBadRoutes(childRoutes, '');
if (badRoutes.length > 0) {
  throw new Error(`Routes missing roles configuration: ${JSON.stringify(badRoutes)}`);
}

const routes = [{
  path: '/',
  childRoutes: [
    ...childRoutes,
    { path: '*', name: 'Page not found', component: PageNotFound, roles: AUTH_ANONYMOUS },
  ].filter(r => r.component || (r.childRoutes && r.childRoutes.length > 0)),
}];

// Handle isIndex property of route config:
//  Dupicate it and put it as the first route rule.
function handleIndexRoute(route) {
  if (!route.childRoutes || !route.childRoutes.length) {
    return;
  }

  const indexRoute = _.find(route.childRoutes, (child => child.isIndex || child.path === 'index'));
  if (indexRoute) {
    const first = { ...indexRoute };
    first.path = '';
    first.exact = true;
    first.autoIndexRoute = true; // mark it so that the simple nav won't show it.
    route.childRoutes.unshift(first);
  }
  route.childRoutes.forEach(handleIndexRoute);
}

routes.forEach(handleIndexRoute);
export default routes;
