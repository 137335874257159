import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

function AppPrinter({
  printOnLoad,
  loading,
  print,
  children
}) {
  const printerRef = React.useRef();

  React.useEffect(() => {
    if (printOnLoad && !loading) {
      window.print();
    }
  }, [printOnLoad, loading, printerRef]);

  React.useEffect(() => {
    if (print && !loading) {
      window.print();
    }
  }, [print, loading, printerRef]);

  if (loading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }
  else {
    return children;
  }
}

AppPrinter.propTypes = {
  loading: PropTypes.bool.isRequired,
  printOnLoad: PropTypes.bool,
  print: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
};

export default AppPrinter;
