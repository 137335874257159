import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import FreteDetail from './FreteDetail';
import AppPrinter from '../common/AppPrinter';

function FretePrintPage({
  match,
  frete,
  actions
}) {
  // parametros da rota
  const { freteId } = match.params;

  const { freteSelecionado, getFreteSelecionadoPending } = frete;

  React.useEffect(() => {
    (async () => {
      await actions.getFreteSelecionado(freteId);
    })();
  }, [freteId]);

  return (
    <>
      <AppPrinter
        printOnLoad={true}
        loading={!freteSelecionado || getFreteSelecionadoPending}
      >
        <FreteDetail 
          loading={!freteSelecionado || getFreteSelecionadoPending}
          frete={freteSelecionado}
        />
      </AppPrinter>
    </>
  );
}

FretePrintPage.propTypes = {
  frete: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    frete: state.frete,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FretePrintPage);
