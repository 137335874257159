import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles(theme => ({
  textField: {
    flexBasis: 200,
  },
}));

export default function AppSecretField({
  startWithPasswordVisible = false,
  input,
  label,
  meta: { touched, invalid, error },
  value,
  onChange,
  options,
  ...other
}) {
  const classes = useStyles();

  const [hidePassword, setHidePassword] = React.useState(!startWithPasswordVisible);
  const handleClickShowPassword = event => {
    setHidePassword(!hidePassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  
  return (
    <FormControl className={clsx(classes.margin, classes.textField)} error={touched && invalid} fullWidth>
      <InputLabel htmlFor={input.name}>{label}</InputLabel>
      <Input
        id={input.name}
        value={value}
        {...input}
        {...other}
        type={hidePassword ? 'password' : 'text'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="alternar visibilidade da senha"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {!hidePassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      { touched && invalid && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}
