// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { LoginPage, AutoAuthPage } from './';
import { AUTH_ANONYMOUS } from '../../common/auth';

export default {
  path: '',
  childRoutes: [
    { path: 'index', component: AutoAuthPage, roles: AUTH_ANONYMOUS },
    { path: 'login', component: LoginPage, roles: AUTH_ANONYMOUS }
  ],
};
