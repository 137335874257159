import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { AppTextField, EnderecoFormFields, AppCpfCnpjTextField, AppDateField, AppTelefoneInput, AppDropzoneArquivoField } from '../common';
import Link from '../common/Link';
import { composeValidators, required, isEmail, isCpfOrCnpj, isNumber } from '../../common/validators';
import { useAddErrorMessage } from '../layout/redux/addSnackMessage';

const useStyles = makeStyles(theme => ({
  fieldsDiv: {
    paddingBottom: theme.spacing(4)
  },
  backButton: {
    marginLeft: 'auto',
  }
}));


function CadastroForm({
  initialValues,
  readOnly,
  onSubmit,
  validate
}) {
  const classes = useStyles();
  const { addErrorMessage } = useAddErrorMessage();

  const [modo, setModo] = React.useState(undefined);
  const [arquivo, setArquivo] = React.useState(null);

  const handleDropRejected = (files) => {
    addErrorMessage(`Arquivo(s) não suportado(s): ${files.map(f => f.name).join(', ')}`)
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, pristine, submitting, form, values }) => (
        <form onSubmit={handleSubmit}>
          <Card className={classes.card}>
            <CardHeader 
              title={
                <Typography component="h5" style={{fontWeight: 500}}>
                  Dados
                </Typography>
              }
              style={{
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '20px',
                letterSpacing: '-0.05px',
              }} />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                  <Field 
                    name="documento" 
                    component={AppCpfCnpjTextField}
                    validate={composeValidators(required, isCpfOrCnpj)}
                    onModoChanged={setModo}
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={5}>
                  <Field 
                    name="nome" 
                    component={AppTextField}
                    label="Nome"
                    validate={required}
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Field 
                    name="email" 
                    component={AppTextField}
                    label="E-mail"
                    validate={composeValidators(required, isEmail)}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Field 
                    name="telefone" 
                    component={AppTextField}
                    InputProps={{
                      inputComponent: AppTelefoneInput,
                    }}
                    label="Telefone"
                    validate={composeValidators(required, isNumber)}
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Field 
                    name="inscricaoEstadual" 
                    component={AppTextField}
                    label="Inscrição Estadual"
                    disabled={readOnly}
                  />
                </Grid>
              </Grid>
            </CardContent>,
            {
              modo === 'cpf' &&
              <>
                <CardHeader 
                  title={
                    <Typography component="h5" style={{fontWeight: 500}}>
                      Pessoa Física
                    </Typography>
                  }
                  style={{
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '20px',
                    letterSpacing: '-0.05px',
                  }} 
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>

                    <Grid item xs={12} lg={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={5} lg={5}>
                          <Field 
                            name="rg.numero" 
                            component={AppTextField}
                            label="RG - Número"
                            validate={required}
                            disabled={readOnly}
                          />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                          <Field 
                            name="rg.orgao" 
                            component={AppTextField}
                            label="RG - Órgão Emissor"
                            validate={required}
                            disabled={readOnly}
                          />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                          <Field name="rg.dataEmissao" label="RG - Data da Emissão" validate={required}>
                            {props => {
                              return (
                                <AppDateField 
                                  disableFuture
                                  openTo="year"
                                  format="dd/MM/yyyy"
                                  views={["year", "month", "date"]}
                                  disabled={readOnly}
                                  {...props}
                                />
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <AppDropzoneArquivoField 
                            name="comprovanteRg" 
                            initialArquivo={arquivo}
                            acceptedFiles={['application/pdf']}
                            filesLimit={100}
                            maxFileSize={5*1024*1024}
                            onDropRejected={handleDropRejected}
                            dropzoneText="Documento de RG - Clique ou arraste o arquivo aqui."
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <AppDropzoneArquivoField 
                            name="comprovanteEndereco" 
                            initialArquivo={arquivo}
                            acceptedFiles={['application/pdf']}
                            filesLimit={100}
                            maxFileSize={5*1024*1024}
                            onDropRejected={handleDropRejected}
                            dropzoneText="Comprovante de Residência - Clique ou arraste o arquivo aqui."
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </>
            }
            {
              modo === 'cnpj' &&
              <>
                <CardHeader 
                  title={
                    <Typography component="h5" style={{fontWeight: 500}}>
                      Pessoa Jurídica
                    </Typography>
                  }
                  style={{
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '20px',
                    letterSpacing: '-0.05px',
                  }} 
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={6}>
                      <Field 
                        name="inscricaoMunicipal" 
                        component={AppTextField}
                        label="Inscrição Municipal"
                        validate={required}
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Field 
                        name="razaoSocial" 
                        component={AppTextField}
                        label="Razão Social"
                        validate={required}
                        disabled={readOnly}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </>
            }
            <CardHeader 
              title={
                <Typography component="h5" style={{fontWeight: 500}}>
                  Endereço
                </Typography>
              }
              style={{
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '20px',
                letterSpacing: '-0.05px',
              }} />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <EnderecoFormFields form={form} values={values}/>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions disableSpacing>
              <Button type="submit" variant="contained" color="primary" className={classes.button} disabled={pristine || submitting}>
                Cadastrar
              </Button>
              <Button 
                className={classes.backButton}
                variant="contained" 
                color="inherit" 
                component={Link} to="/"
              >
                Voltar
            </Button>
            </CardActions>
          </Card>
        </form>
      )}
    />
  );
}

CadastroForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default CadastroForm;
