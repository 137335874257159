export const FRETE_CADASTRAR_FRETE_BEGIN = "FRETE_CADASTRAR_FRETE_BEGIN";
export const FRETE_CADASTRAR_FRETE_SUCCESS = "FRETE_CADASTRAR_FRETE_SUCCESS";
export const FRETE_CADASTRAR_FRETE_FAILURE = "FRETE_CADASTRAR_FRETE_FAILURE";
export const FRETE_CADASTRAR_FRETE_DISMISS_ERROR = "FRETE_CADASTRAR_FRETE_DISMISS_ERROR";
export const FRETE_GET_FRETES_BEGIN = "FRETE_GET_FRETES_BEGIN";
export const FRETE_GET_FRETES_SUCCESS = "FRETE_GET_FRETES_SUCCESS";
export const FRETE_GET_FRETES_FAILURE = "FRETE_GET_FRETES_FAILURE";
export const FRETE_GET_FRETES_DISMISS_ERROR = "FRETE_GET_FRETES_DISMISS_ERROR";
export const FRETE_GET_PRODUTOS_BEGIN = "FRETE_GET_PRODUTOS_BEGIN";
export const FRETE_GET_PRODUTOS_SUCCESS = "FRETE_GET_PRODUTOS_SUCCESS";
export const FRETE_GET_PRODUTOS_FAILURE = "FRETE_GET_PRODUTOS_FAILURE";
export const FRETE_GET_PRODUTOS_DISMISS_ERROR = "FRETE_GET_PRODUTOS_DISMISS_ERROR";
export const FRETE_GET_CARGA_FORMATOS_BEGIN = "FRETE_GET_CARGA_FORMATOS_BEGIN";
export const FRETE_GET_CARGA_FORMATOS_SUCCESS = "FRETE_GET_CARGA_FORMATOS_SUCCESS";
export const FRETE_GET_CARGA_FORMATOS_FAILURE = "FRETE_GET_CARGA_FORMATOS_FAILURE";
export const FRETE_GET_CARGA_FORMATOS_DISMISS_ERROR = "FRETE_GET_CARGA_FORMATOS_DISMISS_ERROR";
export const FRETE_GET_VEICULO_TIPOS_BEGIN = "FRETE_GET_VEICULO_TIPOS_BEGIN";
export const FRETE_GET_VEICULO_TIPOS_SUCCESS = "FRETE_GET_VEICULO_TIPOS_SUCCESS";
export const FRETE_GET_VEICULO_TIPOS_FAILURE = "FRETE_GET_VEICULO_TIPOS_FAILURE";
export const FRETE_GET_VEICULO_TIPOS_DISMISS_ERROR = "FRETE_GET_VEICULO_TIPOS_DISMISS_ERROR";
export const FRETE_GET_VEICULO_CARROCERIAS_BEGIN = "FRETE_GET_VEICULO_CARROCERIAS_BEGIN";
export const FRETE_GET_VEICULO_CARROCERIAS_SUCCESS = "FRETE_GET_VEICULO_CARROCERIAS_SUCCESS";
export const FRETE_GET_VEICULO_CARROCERIAS_FAILURE = "FRETE_GET_VEICULO_CARROCERIAS_FAILURE";
export const FRETE_GET_VEICULO_CARROCERIAS_DISMISS_ERROR = "FRETE_GET_VEICULO_CARROCERIAS_DISMISS_ERROR";
export const FRETE_GET_FRETE_SELECIONADO_BEGIN = "FRETE_GET_FRETE_SELECIONADO_BEGIN";
export const FRETE_GET_FRETE_SELECIONADO_SUCCESS = "FRETE_GET_FRETE_SELECIONADO_SUCCESS";
export const FRETE_GET_FRETE_SELECIONADO_FAILURE = "FRETE_GET_FRETE_SELECIONADO_FAILURE";
export const FRETE_GET_FRETE_SELECIONADO_DISMISS_ERROR = "FRETE_GET_FRETE_SELECIONADO_DISMISS_ERROR";
export const FRETE_APROVAR_FRETE_BEGIN = "FRETE_APROVAR_FRETE_BEGIN";
export const FRETE_APROVAR_FRETE_SUCCESS = "FRETE_APROVAR_FRETE_SUCCESS";
export const FRETE_APROVAR_FRETE_FAILURE = "FRETE_APROVAR_FRETE_FAILURE";
export const FRETE_APROVAR_FRETE_DISMISS_ERROR = "FRETE_APROVAR_FRETE_DISMISS_ERROR";
export const FRETE_UPDATE_FRETES_PAGINATION = 'FRETE_UPDATE_FRETES_PAGINATION';