// This is the root reducer of the feature. It is used for:
//   1. Load reducers from each action in the feature and process them one by one.
//      Note that this part of code is mainly maintained by Rekit, you usually don't need to edit them.
//   2. Write cross-topic reducers. If a reducer is not bound to some specific action.
//      Then it could be written here.
// Learn more from the introduction of this approach:
// https://medium.com/@nate_wang/a-new-approach-for-managing-redux-actions-91c26ce8b5da.

import initialState from './initialState';
import { reducer as getFreteMotivosReducer } from './getFreteMotivos';
import { reducer as cadastrarFreteReducer } from './cadastrarFrete';
import { reducer as getFretesReducer } from './getFretes';
import { reducer as getProdutosReducer } from './getProdutos';
import { reducer as getCargaFormatosReducer } from './getCargaFormatos';
import { reducer as getVeiculoTiposReducer } from './getVeiculoTipos';
import { reducer as getVeiculoCarroceriasReducer } from './getVeiculoCarrocerias';
import { reducer as getFreteSelecionadoReducer } from './getFreteSelecionado';
import { reducer as aprovarFreteReducer } from './aprovarFrete';
import { reducer as updateFretesPaginationReducer } from './updateFretesPagination';

const reducers = [
  getFreteMotivosReducer,
  cadastrarFreteReducer,
  getFretesReducer,
  getProdutosReducer,
  getCargaFormatosReducer,
  getVeiculoTiposReducer,
  getVeiculoCarroceriasReducer,
  getFreteSelecionadoReducer,
  aprovarFreteReducer,
  updateFretesPaginationReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
