import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SerranaClient from '../../../clients/serrana';
import { createStructuredSelector } from 'reselect';

export const LOCAL_REMOVER_LOCAL_BEGIN = 'LOCAL_REMOVER_LOCAL_BEGIN';
export const LOCAL_REMOVER_LOCAL_SUCCESS = 'LOCAL_REMOVER_LOCAL_SUCCESS';
export const LOCAL_REMOVER_LOCAL_FAILURE = 'LOCAL_REMOVER_LOCAL_FAILURE';
export const LOCAL_REMOVER_LOCAL_DISMISS_ERROR = 'LOCAL_REMOVER_LOCAL_DISMISS_ERROR';


export function useRemoverLocal() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => {
      return removerLocal(...params)(dispatch);
    },
    [dispatch]
  );
  return { removerLocal: boundAction };
}

export function removerLocalStateSelector() {
  return createStructuredSelector({
    removerLocalPending: state => state.local.removerLocalPending,
    removerLocalError: state => state.local.removerLocalError,
  });
}

export function useRemoverLocalState() {
  return useSelector(removerLocalStateSelector());
}

export function removerLocal(localId) {
  return async (dispatch) => {
    
    dispatch({
      type: LOCAL_REMOVER_LOCAL_BEGIN,
    });

    try {
      const local = await SerranaClient.removerLocal(localId)
      dispatch({
        type: LOCAL_REMOVER_LOCAL_SUCCESS,
        data: {
          local
        },
      });

      return local;
    }
    catch (err) {
      dispatch({
        type: LOCAL_REMOVER_LOCAL_FAILURE,
        data: { error: err },
      });
      
      throw err;
    }
  };
}

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export function dismissRemoverLocalError() {
  return {
    type: LOCAL_REMOVER_LOCAL_DISMISS_ERROR,
  };
}

export function reducer(state, { type, data }) {
  switch (type) {
    case LOCAL_REMOVER_LOCAL_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        removerLocalPending: true,
        removerLocalError: null,
      };

    case LOCAL_REMOVER_LOCAL_SUCCESS:
      // The request is success
      const { local } = data;

      // update state
      return {
        ...state,
        removerLocalPending: false,
        removerLocalError: null,
      }

    case LOCAL_REMOVER_LOCAL_FAILURE:
      // The request is failed
      return {
        ...state,
        removerLocalPending: false,
        removerLocalError: data.error,
      };

    case LOCAL_REMOVER_LOCAL_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        removerLocalError: null,
      };

    default:
      return state;
  }
}

export default reducer;