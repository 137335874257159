import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import SerranaClient from '../../../clients/serrana';

export const LOCAL_CADASTRAR_LOCAL_BEGIN = 'LOCAL_CADASTRAR_LOCAL_BEGIN';
export const LOCAL_CADASTRAR_LOCAL_SUCCESS = 'LOCAL_CADASTRAR_LOCAL_SUCCESS';
export const LOCAL_CADASTRAR_LOCAL_FAILURE = 'LOCAL_CADASTRAR_LOCAL_FAILURE';
export const LOCAL_CADASTRAR_LOCAL_DISMISS_ERROR = 'LOCAL_CADASTRAR_LOCAL_DISMISS_ERROR';


export function useCadastrarLocal() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => {
      return cadastrarLocal(...params)(dispatch);
    },
    [dispatch]
  );
  return { cadastrarLocal: boundAction };
}

export function cadastrarLocal(local) {
  return async (dispatch) => {
    
    dispatch({
      type: LOCAL_CADASTRAR_LOCAL_BEGIN,
    });

    try {
      const res = await SerranaClient.cadastrarLocal(local);
      dispatch({
        type: LOCAL_CADASTRAR_LOCAL_SUCCESS,
        data: {
          res
        },
      });

      return res;
    }
    catch (err) {
      dispatch({
        type: LOCAL_CADASTRAR_LOCAL_FAILURE,
        data: { error: err },
      });
      
      throw err;
    }
  };
}

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export function dismissCadastrarLocalError() {
  return {
    type: LOCAL_CADASTRAR_LOCAL_DISMISS_ERROR,
  };
}

export function reducer(state, { type, data }) {
  switch (type) {
    case LOCAL_CADASTRAR_LOCAL_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        cadastrarLocalPending: true,
        cadastrarLocalError: null,
      };

    case LOCAL_CADASTRAR_LOCAL_SUCCESS:
      // The request is success
      const { res } = data;

      // update state
      return {
        ...state,
        cadastrarLocalPending: false,
        cadastrarLocalError: null,
      }

    case LOCAL_CADASTRAR_LOCAL_FAILURE:
      // The request is failed
      return {
        ...state,
        cadastrarLocalPending: false,
        cadastrarLocalError: data.error,
      };

    case LOCAL_CADASTRAR_LOCAL_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        cadastrarLocalError: null,
      };

    default:
      return state;
  }
}

export default reducer;