import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import parseIsoDate from 'date-fns/parseISO';
import formatDate from 'date-fns/format';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import ArchiveIcon from '@material-ui/icons/Archive';
import PublishIcon from '@material-ui/icons/Publish';
import Typography from '@material-ui/core/Typography';

import { SERRANA_GOOGLE_MAPS_KEY } from '../../clients/serrana';
import { FRETE_SITUACAO_ICON} from './FreteSituacaoChip';

// os componentes do material-ui "reclamam" por receberem propriedades desconhecidas
const MapMarkerWrapper = ({children}) => {
  return children;
}

MapMarkerWrapper.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
}

const FreteEventoItem = ({
  IconComponent,
  titulo,
  descricao,
  dataHora,
  color,
  onClick,
  setVisisble,
  visible
}) => {
  if (_.isString(dataHora)) {
    dataHora = parseIsoDate(dataHora);
  }

  return (
    <ListItem button onClick={onClick}>
      <Checkbox checked={visible} onClick={() => setVisisble(!visible)} />
      <ListItemAvatar>
        <Avatar style={{color: color}}>
          <IconComponent />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={titulo}
        secondary={formatDate(dataHora, "dd/MM/yyyy HH:mm:ss")}
      />
      <ListItemSecondaryAction>
        <Typography variant="subtitle2"></Typography>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

FreteEventoItem.propTypes = {
  IconComponent: PropTypes.elementType.isRequired,
  titulo: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

const useStyles = makeStyles(theme => ({
  mapCard: {
    height: 400,
    width: '100%',
    marginBottom: theme.spacing(4),
  }
}));

const createMarkerComponent = ({IconComponent, color, key}) => (<IconComponent key={key} style={{color: color}} />);

function FreteMap({
  frete
}) {
  const classes = useStyles();

  const viagem = frete.viagemAtual;

  const markers = [
    {
      lat: viagem.posicaoAtual.latitude,
      lng: viagem.posicaoAtual.longitude,
      IconComponent: LocalShippingIcon,
      dataHora: new Date(),
      titulo: "Posição Atual",
      descricao: "Última posição enviada pelo cooperado",
      color: "black"
    }
  ];

  if (viagem.dataAssociacao) {
    markers.push({
      lat: viagem.posicaoColeta.latitude,
      lng: viagem.posicaoColeta.longitude,
      IconComponent: PersonPinCircleIcon,
      dataHora: viagem.dataAssociacao,
      titulo: "Aceite do Fornecedor",
      descricao: "Posição aonde o fornecedor aceitou a demanda.",
      color: "black"
    });
  }

  let i = 1;
  for (const origem of frete.origens) {
    if (origem.coleta) {
      markers.push({
        lat: viagem.posicaoColeta.latitude,
        lng: viagem.posicaoColeta.longitude,
        IconComponent: PublishIcon,
        dataHora: origem.coleta.data,
        titulo: `Coleta Origem ${i}`,
        descricao: `${origem.endereco.cidadeNome} - ${origem.endereco.uf}`,
        color: "black"
      });
    }
    i++;
  }

  i = 1;
  for (const destino of frete.destinos) {
    let j = 1;
    for (const entrega of destino.entregas) {
      markers.push({
        lat: entrega.posicao.latitude,
        lng: entrega.posicao.longitude,
        IconComponent: ArchiveIcon,
        dataHora: entrega.data,
        titulo: `Destino ${i} - Entrega ${j}/${destino.numeroEntregas}`,
        descricao: `Posição aonde foi feita uma entrega`,
        color: "black"
      });
      j++;
    }
  }

  if (viagem.dataEntrega) {
    markers.push({
      lat: viagem.posicaoEntrega.latitude,
      lng: viagem.posicaoEntrega.longitude,
      IconComponent: FRETE_SITUACAO_ICON['entregue'],
      dataHora: viagem.dataEntrega,
      titulo: 'Entrega final',
      descricao: 'Posição aonde o frete foi finalizado',
      color: "black"
    });
  }

  
  for (const marker of markers) {
    const [markerVisible, setMarkerVisisble] = React.useState(true);
    marker.visible = markerVisible;
    marker.setVisisble = setMarkerVisisble;
  }

  const [mapState, setMapState] = React.useState({
    center: [viagem.posicaoAtual.latitude, viagem.posicaoAtual.longitude],
    zoom: 11
  });

  const setMarkerOnCenter = (marker) => {
    setMapState({
      ...mapState,
      center: [marker.lat, marker.lng]
    })
  }

  const onMapChange = ({center, zoom}) => {
    setMapState({
      center: center,
      zoom: zoom,      
    });
  }
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <div className={classes.mapCard}>
          <GoogleMapReact className={classes.mapCard}
            bootstrapURLKeys={{
              key: SERRANA_GOOGLE_MAPS_KEY,
              language: 'pt-BR',
              region: 'br',
            }}
            onChange={onMapChange}
            center={mapState.center}
            zoom={mapState.zoom}
          >
            <For each="marker" index="idx" of={markers.filter(m => m.visible)}>
              <MapMarkerWrapper
                key={idx}
                lat={marker.lat}
                lng={marker.lng}
              >
                {createMarkerComponent(marker)}
              </MapMarkerWrapper>
            </For>
          </GoogleMapReact>
        </div>
      </Grid>
      <Grid item xs={3}>
        <List dense={true}>
          <For each="marker" index="idx" of={markers}>
            <FreteEventoItem key={idx} {...marker} onClick={() => setMarkerOnCenter(marker)} />
          </For>
        </List>
      </Grid>

    </Grid>
  );
}

FreteMap.propTypes = {
  frete: PropTypes.object.isRequired,
};

export default FreteMap;
