import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  LAYOUT_ADD_SNACK_MESSAGE,
} from './constants';

import { closeSnackMessage } from './closeSnackMessage';

let snackIdFactory = 0;

export function addSnackMessage(variant, message, ...props) {
  return (dispatch) => {
    const snackId = snackIdFactory++;

    const onClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      
      dispatch(closeSnackMessage(snackId));
    };

    const snack = {
      snackId,
      variant,
      message,
      open: true,
      onClose,
      ...props
    }

    dispatch({
      type: LAYOUT_ADD_SNACK_MESSAGE,
      data: {
        snack
      }
    });
  }
}

export function useAddSnackMessage() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => addSnackMessage(...params)(dispatch), [dispatch]);
  return { addSnackMessage: boundAction };
}

export function useAddErrorMessage() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => addSnackMessage('error', ...params)(dispatch), [dispatch]);
  return { addErrorMessage: boundAction };
}

export function useAddSuccessMessage() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => addSnackMessage('success', ...params)(dispatch), [dispatch]);
  return { addSuccessMessage: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case LAYOUT_ADD_SNACK_MESSAGE:
      return {
        ...state,
        messages: [action.data.snack, ...state.messages]
      };

    default:
      return state;
  }
}
