// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { ChamadosListPage, NovoChamadoPage } from './';

import { ChamadoViewPage } from './';

export default {
  path: 'suporte',
  childRoutes: [
    { path: 'index', component: ChamadosListPage, roles: ['ROLE_CLIENTE']},
    { path: 'new', component: NovoChamadoPage, roles: ['ROLE_CLIENTE']},
    { path: '/suporte/chamado/:chamadoId', component: ChamadoViewPage, roles: ['ROLE_CLIENTE'] },
  ],
};
