import React from 'react';
import PropTypes from 'prop-types';
import 'date-fns';
import brLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';


function AppDateField({
  label,
  placeholder,
  input,
  meta: { touched, invalid, error },
  ...custom
}) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
        <DatePicker
          label={label}
          placeholder={placeholder}
          error={touched && invalid}
          helperText={touched && error}
          invalidDateMessage="Data inválida"
          cancelLabel="Cancelar"
          {...input}
          value={input.value === "" ? null : input.value}
          {...custom}
        />
    </MuiPickersUtilsProvider>
  );
}

AppDateField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default AppDateField;
