import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import SerranaClient from '../../../clients/serrana';

import {
  FRETE_GET_VEICULO_TIPOS_BEGIN,
  FRETE_GET_VEICULO_TIPOS_SUCCESS,
  FRETE_GET_VEICULO_TIPOS_FAILURE,
  FRETE_GET_VEICULO_TIPOS_DISMISS_ERROR,
} from './constants';


export function useGetVeiculoTipos() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => { 
      return getVeiculoTipos(...params)(dispatch);
    },
    [dispatch]
  );
  return { getVeiculoTipos: boundAction };
}

export function getVeiculoTipos() {
  return async (dispatch) => {
    
    dispatch({
      type: FRETE_GET_VEICULO_TIPOS_BEGIN,
    });

    try {
      let veiculoTipos = await SerranaClient.getVeiculoTipos();
      
      dispatch({
        type: FRETE_GET_VEICULO_TIPOS_SUCCESS,
        data: veiculoTipos,
      });

      return veiculoTipos;
    }
    catch (err) {
      dispatch({
        type: FRETE_GET_VEICULO_TIPOS_FAILURE,
        data: { error: err },
      });
      
      throw err;
    }
  };
}

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export function dismissGetVeiculoTiposError() {
  return {
    type: FRETE_GET_VEICULO_TIPOS_DISMISS_ERROR,
  };
}

const initialState = {
  fretes: []
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case FRETE_GET_VEICULO_TIPOS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getVeiculoTiposPending: true,
        getVeiculoTiposError: null,
      };

    case FRETE_GET_VEICULO_TIPOS_SUCCESS:
      // The request is success
      return {
        ...state,
        veiculoTipos: action.data,
        getVeiculoTiposPending: false,
        getVeiculoTiposError: null,
      };

    case FRETE_GET_VEICULO_TIPOS_FAILURE:
      // The request is failed
      return {
        ...state,
        getVeiculoTiposPending: false,
        getVeiculoTiposError: action.data.error,
      };

    case FRETE_GET_VEICULO_TIPOS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getVeiculoTiposError: null,
      };

    default:
      return state;
  }
}