import React from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Link from '../common/Link';

import SerranaLayout from '../layout/SerranaLayout';
import { useAddErrorMessage, useAddSuccessMessage } from '../layout/redux/addSnackMessage';

import { useGetFreteSelecionado } from './redux/getFreteSelecionado';
import { useAprovarFrete } from './redux/aprovarFrete';
import FreteDetail from './FreteDetail';
import { LinearProgress } from '@material-ui/core';
import { SnackbarMessage } from '../common';
import { FreteRecusaDialog } from '.';

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: theme.spacing(4),
  },
  table: {

  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  progressDiv: {
    flex: 1,
  }
}));

const FreteViewPage = ({
  match, 
  freteSelecionado, fretes, getFreteSelecionadoPending, aprovarFretePending 
  }) => {
  // parametros da rota
  const { freteId } = match.params;

  // actions
  const { getFreteSelecionado } = useGetFreteSelecionado();
  const { aprovarFrete } = useAprovarFrete();
  const { addErrorMessage } = useAddErrorMessage();
  const { addSuccessMessage } = useAddSuccessMessage();
  const [ dialogOpen, setDialogOpen ] = React.useState('');

  React.useEffect(() => {
    getFreteSelecionado(freteId);
  }, [freteId]);

  const frete = freteSelecionado;

  const onAprovarFrete = async () => {
    try {
      await aprovarFrete(freteId);
      setDialogOpen('');
      getFreteSelecionado(freteId);
      addSuccessMessage('Frete aprovado com sucesso!');
    }
    catch (e) {
      addErrorMessage(e.message);
    }
  }

  const classes = useStyles();

  return (
    <SerranaLayout>
      <Grid container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={3}
          style={{marginBottom: 2}}>
        <Grid item>
          <Typography component="h2" variant="overline">
            Frete
          </Typography>
          <Typography component="h1" variant="h3" style={{
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '28px',
              letterSpacing: '-0.06px',
            }}>
            Visualizar frete
          </Typography>
        </Grid>
        <Grid item>
          <Button 
            variant="contained" 
            color="inherit" 
            className={classes.button}
            component={Link} to="/frete"
            >
            Voltar
          </Button>
        </Grid>
      </Grid>
      
      {
        (!frete || getFreteSelecionadoPending) ?
        (
          (getFreteSelecionadoPending) ?
          <div>
            <LinearProgress />
          </div>
          :
          <SnackbarMessage variant='error' message='Ocorreu um erro carregando os detalhes do frete. Tente novamente mais tarde.' />
        )
        :
        <>
          <FreteDetail 
            frete={frete} 
            orcadoCardActions={
              <>
                <Button 
                  variant="contained" 
                  color="primary" 
                  className={classes.button}
                  onClick={() => setDialogOpen('aprovar') }
                  disabled={aprovarFretePending}
                >
                  Aprovar
                </Button>
                <Button 
                  variant="outlined" 
                  color="secondary" 
                  className={classes.button}
                  onClick={() => setDialogOpen('rejeitar') }>
                  Rejeitar
                </Button>
              </>
            }
          />
          <FreteRecusaDialog
            open={dialogOpen === 'rejeitar'}
            onClose={() => setDialogOpen('')}
            freteSelecionado={frete}
          />
        </>
      }

      <Dialog
        open={dialogOpen === 'aprovar'}
        onClose={() => setDialogOpen('')}
        aria-labelledby="dialog-aprovar-title"
      >
        <DialogTitle id="dialog-aprovar-title">
          Aprovar frete
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Você realmente deseja aprovar esse orçamento? Esta ação não poderá ser desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            autoFocus 
            onClick={() => setDialogOpen('')} 
            color="primary"
            disabled={aprovarFretePending}>
            Cancel
          </Button>
          <Button 
            onClick={onAprovarFrete}
            color="primary"
            disabled={aprovarFretePending}>
            Sim, aprovar
          </Button>
        </DialogActions>
      </Dialog>
    </SerranaLayout>
  );
}

/* istanbul ignore next */
function mapStateToProps(state) {
  const { fretes, freteSelecionado, getFreteSelecionadoPending, aprovarFretePending } = state.frete;
  return { fretes, freteSelecionado, getFreteSelecionadoPending, aprovarFretePending }
}

export default connect(
  mapStateToProps
)(FreteViewPage);
