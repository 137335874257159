import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import AppTextField from '../common/AppTextField';
import { required } from '../../common/validators';
import { EnderecoFormFields } from '../common';

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: theme.spacing(2),
  },
}));

export function LocalForm({
  onSubmit,
  local
}) {
  const classes = useStyles();
  return (
    <Form 
      initialValues={local}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, pristine, form, submitting, values }) => {

        return (
          <form onSubmit={handleSubmit}>
            <Card className={classes.card}>
              <CardHeader 
                title={
                  <Typography component="h5" style={{fontWeight: 500}}>
                    Local
                  </Typography>
                }
                style={{
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '20px',
                  letterSpacing: '-0.05px',
                }} />
              <Divider />
              <CardContent>
                <Grid container spacing={3} alignItems="center">
                  <Grid item lg={12} xs={12}>
                    <Field 
                      name="nome" 
                      component={AppTextField} 
                      label="Nome"
                      placeholder="Nome do local (Ex: Matriz)" 
                      validate={required}
                    />
                  </Grid>
                  <EnderecoFormFields form={form} values={values}/>
                </Grid>
              </CardContent>
              <Divider/>
              <CardActions>
                <Button type="submit" variant="contained" color="primary" disabled={pristine || submitting}>
                  Salvar
                </Button>
              </CardActions>
            </Card>
          </form>
        )
      }}
    </Form>
  );
}

LocalForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default LocalForm;
